<template>
  <div>

    <div class="create-container">
      <div class="create-header">
      </div>

      <div>

        <div v-if="$route.params.id" class="profile-container">

          <div class="flex-row-center">
            <div class="profile-circle" :style="imageClass" />
            <div class="profile-detail">
              <div v-if="profile.name" class="profile-name"> {{ profile.name }} </div>
              <div v-if="profile.title" class="profile-title"> {{ profile.title }} </div>
              <div v-if="geoip.country" class="profile-country"> {{ localize(getCountry()) }} </div>
            </div>
            <div
              class="profile-contact"
              @click="openChat"
            >
              <div
                class="messages-icon"
                v-html="getRawIcon('MESSAGES_DARK')"
              />
              <h2 class="createtitletext">{{ localize('TEXT_CONTACT') }}</h2>
            </div>
          </div>
          <div class="profile-description"> {{ profile.description }} </div>

        </div>
        <div v-else>

          <div class="profile-container">

            <h2 class="createtitletext">
              {{ localize('TEXT_PROFILE') }}
            </h2>

            <div class="flex-row-wrap">

              <div class="create-upload-container">
                <form class="create-upload-circle" :style="imageClass">
                  <label
                    class="create-profile-file-upload"
                    for="create-profile-file-upload"
                  />
                  <input
                    id="create-profile-file-upload"
                    type="file"
                    accept="image/*"
                    @change="addProfileImage"
                  >
                </form>
              </div>

              <div class="segment-container">
                <div>
                  <CInput
                    class="margin-top"
                    :value="profile.name"
                    :title="localize('TEXT_NAME')"
                    :placeholder="localize('TEXT_NAME')"
                    @inputValue="profile.name = $event"
                  />
                </div>

                <div>
                  <CInput
                    class="margin-top"
                    :value="profile.title"
                    :title="localize('TEXT_TITLE')"
                    :placeholder="localize('TEXT_TITLE')"
                    @inputValue="profile.title = $event"
                  />
                </div>

                <div>
                  <CTextArea
                    class="token-description"
                    :value="profile.description"
                    :title="localize('TEXT_DESCRIPTION')"
                    :placeholder="localize('TEXT_DESCRIPTION')"
                    @inputValue="profile.description = $event"
                  />
                </div>

                <div class="flex-row-wrap">
<!--
                  <CDropdown
                    :title="localize('TEXT_DAYS_DUE')"
                    :toggler-text="profile"
                    class="create-dropdown-item"
                  >
                    <CDropdownItem
                      v-for="i in 30"
                      :key="i"
                      @click.native="profile = i"
                    >
                      {{ i }}
                    </CDropdownItem>
                  </CDropdown>
-->
                </div>
              </div>
            </div>

            <div v-if="isMin == false">

              <h2 class="createtitletext">
                {{ localize('TEXT_PROMOTIONAL_VIDEO') }}
              </h2>

              <div class="flex-row-wrap">
                <div class="create-upload-container">
                  <form class="create-upload-box">
                    <label
                      class="create-intro-file-upload"
                      for="create-intro-file-upload"
                    />
                    <input
                      id="create-intro-file-upload"
                      type="file"
                      accept="video/*"
                      @change="addCreativeFiles"
                    >
                  </form>
                  <div class="sub-script">
                    {{ localize('CREATE_CHOOSE_FILE') }}
                  </div>
                </div>

                <video
                  v-if="profile?.media?.media_filename_preview"
                  class="profile-video"
                  loop="true"
                  autoplay="true"
                  :src="getURL(profile.media.media_filename)"
                  muted
                />
              </div>
            </div>
          </div>

          <div class="flex-row">
            <div
              class="create-save-button"
              @click="saveProfile"
            >
              {{ localize('TEXT_SAVE') }}
            </div>

          </div>

        </div>

      </div>

    </div>

    <Transition>
      <CModal
        v-if="showAddModal"
        color="#f9b115"
        title="Validation Error"
        @close="showAddModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_UPDATE') }} </div>
        </template>
        <template #body>
          <div>
            {{ showAddMessage }}

            <div
              @click="viewProfile"
            >
        
              {{localize('VIEW_PROFILE')}}
              
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, emit, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import Axios from "axios";
import FormData from "form-data";
import {ERC20FactoryABI} from "../abi.js";

export default {
    "name": "List",
    "components": {
    },
    "props": {
        "isMin": {
            "type": Boolean,
            "default": false
        }
    },
    setup (props, {emit}) {

        const translation = translationStore(),
         //   serverConfig = toRefs(inject("serverConfig")),
            serverConfig = inject("serverConfig"),
            route = inject("route"),
            router = inject("router"),
            web3 = inject("web3"),
            userInfo = inject("userInfo"),
            icon = iconStore(),
            isMin = ref(props.isMin),
            geoip = ref({}),
            address = ref(),
            createUploadBoxClass = ref('create-upload-box'),
            showAddMessage = ref(),
            showAddModal = ref(false),
            imageClass = ref(),
            profile = ref({
                "id":"",
                "name":"",
                "title":"",
                "image":"",
                "description":"",
                "media":{}
            }),
            videoTypes = reactive([
                "video/mp4",
                "video/mpeg",
                "video/x-msvideo",
                "video/webm"
            ]),
            imageTypes = reactive([
                "image/gif",
                "image/jpeg",
                "image/avif",
                "image/png",
                "image/svg",
                "image/svg+xml",
                "image/webp"
            ]),
            maxVideoSize = ref("1000000000"),
            maxImageSize = ref("10000000"),
            showValidateModal = ref(false),
            validateError = ref(),
            profileContainer = ref(),
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            openChat = function() {

                const params = {

                    users: [profile.value.user_id, userInfo.user_id]

                }

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}openChat`,
                    {

                        params

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                              if (response.data.roomId) {

                                  gotoPath('messenger/' + response.data.roomId);

                              }

                        }

                })

            },
            viewProfile = function() {

                window.open('https://www.thumpr.xyz/profile/' + profile.user_id, '_blank').focus();

            },
            getCountry = function () {

                return "COUNTRY_" + geoip.value.country;

            },
            getMyListings = async function (callback) {

                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0];

                address.value = addr;

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getMyTokens`,
                    {

                        "params": {

                            addr

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            let i = 0;
                            for (i; i < response.data.length; i++) {

                            }

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getProfile = function (user_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getProfile`,
                    {

                        "params": {

                            userId:user_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                profile.value = response.data;

                                if (profile.value.geoip) {

                                    try {

                                        geoip.value = JSON.parse(profile.value.geoip);

                                    } catch(e) {

                                        console.log(e)

                                    }

                                }
                                setProfileImage();

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            addProfileImage = function (e) {

                const files = e.target.files;
                // it's a for loop but iirc it only accepts one upload at a time?
                for (let i = 0; i < files.length; i++) {

                    const file = files[i];

                    if (imageTypes.indexOf(files[i].type) > -1) {

                        getMediaDimensions(
                            file,
                            "image",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addMedia(
                                    file,
                                    "image",
                                    (r) => {

                                        media.id = r.id;
                                        media.loading = false;
                                        media.media_filename = r.media_filename;
                                        media.media_filename_preview = r.media_filename_preview;
                                        media.original_filename = r.original_filename;
                                        media.url = r.url;

                                        profile.value.image = r.media_filename;

                                        setProfileImage();

                                    }
                                );

                            }
                        );

                    }

                }

            },
            addCreativeFiles = function (e) {

                const files = e.target.files;
                // it's a for loop but iirc it only accepts one upload at a time?
                for (let i = 0; i < files.length; i++) {

                    const file = files[i];

                    if (imageTypes.indexOf(files[i].type) > -1) {

                        getMediaDimensions(
                            file,
                            "image",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addMedia(
                                    file,
                                    "image",
                                    (r) => {

                                        media.id = r.id;
                                        media.video = false;
                                        media.loading = false;
                                        media.media_filename = r.media_filename;
                                        media.media_filename_preview = r.media_filename_preview;
                                        media.original_filename = r.original_filename;
                                        media.url = r.url;

                                        profile.value.media = media;

                                    }
                                );

                            }
                        );

                    } else if (videoTypes.indexOf(files[i].type) > -1) {

                        const file = files[i];

                        getMediaDimensions(
                            file,
                            "video",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addMedia(
                                    file,
                                    "video",
                                    (r) => {

                                        // delay as the server side image may not be ready
                                        setTimeout(
                                            () => {

                                                media.id = r.id;
                                                media.video = true;
                                                media.loading = false;
                                                media.media_filename = r.media_filename;
                                                media.media_filename_preview = r.media_filename_preview;
                                                media.original_filename = r.original_filename;
                                                media.url = r.url;

                                                profile.value.media = media;

                                            },
                                            3000
                                        );

                                    }

                                );

                            }

                        );

                    } else {

                        console.log("not a supported type");
                        return;

                    }

                }

            },
            addMedia = async function (file, t, callback) {

                const data = new FormData();

                data.append(
                    "file",
                    file
                );
                data.append(
                    "type",
                    t
                );

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addProductFile`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "multipart/form-data"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            callback(response.data);

                        } else {

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            saveProfile = function () {

                console.log(profile.value)
                console.log("profile.value.name")
                console.log(profile.value.name)
                console.log("profile.value.image")
                console.log(profile.value.image)

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addProfile`,
                    {"profile":profile.value},
                    {

                        "headers": {

                            "Content-Type": "application/json"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            emit(
                                "close",
                                false
                            );

                            if (parseInt(profile.value.user_id)) {

                                showAddMessage.value = "Profile Successfully Updated !";
                                showAddModal.value = true;

                            } else {

                                showAddMessage.value = "Profile Successfully Added !";
                                showAddModal.value = true;

                            }

                        } else {

                                showAddMessage.value = "Error Updating Profile !";
                                showAddModal.value = true;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            getMediaDimensions = async function (f, t, c) {

                const dimensions = {};
                if (t === "video") {

                    const $video = document.createElement("video");
                    $video.src = URL.createObjectURL(f);
                    $video.addEventListener(
                        "loadedmetadata",
                        function (e) {

                            console.log(e);
                            dimensions.x = this.videoWidth;
                            dimensions.y = this.videoLength;
                            c(dimensions);

                        }
                    );
                    setTimeout(
                        () => {

                            $video.removeEventListener(
                                "loadedmetadata",
                                () => {},
                                false
                            );

                        },
                        3000
                    );

                } else if (t === "image") {

                    const img = new Image();
                    img.src = URL.createObjectURL(f);
                    img.onload = function () {

                        dimensions.x = img.width;
                        dimensions.y = img.height;
                        c(dimensions);

                    };

                } else {

                    c();

                }

            },
            setProfileImage = function() {

                imageClass.value = {
                    height: "256px",
                    width: "256px",
                    border: "1px solid #efefef",
                    borderRadius: "50%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    opacity: "1.0",
                    background: "url('"+getURL(profile.value.image)+"') #efefef",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            updateView = function (i) {

                if (serverConfig.view === "desktop") {

                } else if (serverConfig.view === "tablet") {

                } else {

                }

            };

        onMounted(() => {

            if (route.params.id) {

                getProfile(route.params.id)

            } else {

                setTimeout(()=>{

                    getProfile(userInfo.user_id)

                }, 500);

            }

            watch(
                serverConfig.chainId,
                () => {

                }
            );

        });

        return {"localize": translation.localize,
            icon,
            web3,
            showValidateModal,
            validateError,
            address,
            geoip,
            getCountry,
            getURL,
            getProfile,
            gotoPath,
            isMin,
            saveProfile,
            updateView,
            viewProfile,
            getRawIcon,
            profile,
            openChat,
            addMedia,
            addCreativeFiles,
            addProfileImage,
            setProfileImage,
            showAddMessage,
            showAddModal,
            profileContainer,
            imageClass,
            createUploadBoxClass,
            getMediaDimensions,
            serverConfig,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.margin-top {
  margin-top: 20px;
}
.create-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.profile-circle {
  height: 256px;
  width: 256px;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-circle {
  height: 256px;
  width: 256px;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box {
  height: 100px;
  width: 200px;
  border: 1px solid grey;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  opacity: 1.0;
  cursor: pointer;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
input#create-profile-file-upload {
  display: none;
}
label.create-profile-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
input#create-intro-file-upload {
  display: none;
}
label.create-intro-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.segment-container {
  max-width: 500px;
  width: 100%;
}
.create-save-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.profile-video {
  width: 100%;
  max-width: 720px;
  margin: 20px;
  border-radius: 20px;
}
.create-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-container {
  margin: 20px auto;
  padding: 20px;
  border-radius: 12px;
  font-family: 'Arial', sans-serif;
  transition: transform 0.2s ease-in-out;
}

.profile-container:hover {
}

/* Profile Name */
.profile-name {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.profile-title {
  font-size: 1.2em;
  font-weight: 600;
  color: #555;
  margin-bottom: 12px;
  text-align: center;
}

.profile-description {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
  margin-top: 50px;
  margin-bottom: 16px;
  text-align: justify;
}

.profile-country {
  font-size: 0.9em;
  color: #888;
  font-weight: 600;
  text-align: right;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 15px;
  }

  .profile-name {
    font-size: 1.6em;
  }

  .profile-title {
    font-size: 1.1em;
  }

  .profile-description {
    font-size: 0.95em;
  }

  .profile-country {
    font-size: 0.85em;
  }
}
.profile-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}
.profile-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  padding: 18px;
  font-size: 1em;
  font-weight: 700;
  border: 1px solid #afafaf;
  height: 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: box-shadow .3s ease;
  box-shadow: 1px 1px 5px rgba(0,0,0,.2);
}
.profile-contact:hover {
  box-shadow: 4px 4px 20px rgba(0,0,0,.3);
}
.profile-contact:active {
  box-shadow: 1px 1px 5px rgba(0,0,0,.2);
}
.messages-icon {
  margin-right: 20px;
  width: 48px;
  height: 48px;
}
.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-address {
}

</style>
