export const orderBookABI = [
  { inputs: [], name: "InvalidInitialization", type: "error" },
  { inputs: [], name: "NotInitializing", type: "error" },
  { inputs: [], name: "ReentrancyGuardReentrantCall", type: "error" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "uint256", name: "orderId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          {
            internalType: "enum OrderBook.DisputeStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "disputeTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Dispute",
        name: "dispute",
        type: "tuple",
      },
    ],
    name: "acceptDisputeEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "acceptOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "cancelOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "completeOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "uint256", name: "orderId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          {
            internalType: "enum OrderBook.DisputeStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "disputeTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Dispute",
        name: "dispute",
        type: "tuple",
      },
    ],
    name: "createDisputeEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "deliverOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "disputeOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "newOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "rejectOrderEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "uint256", name: "orderId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          {
            internalType: "enum OrderBook.DisputeStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "disputeTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Dispute",
        name: "dispute",
        type: "tuple",
      },
    ],
    name: "resolveDisputeEvent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "orderId",
        type: "uint256",
      },
      {
        components: [
          { internalType: "uint256", name: "orderId", type: "uint256" },
          { internalType: "uint256", name: "amount", type: "uint256" },
          {
            internalType: "enum OrderBook.DisputeStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "disputeTimestamp",
            type: "uint256",
          },
        ],
        indexed: false,
        internalType: "struct OrderBook.Dispute",
        name: "dispute",
        type: "tuple",
      },
    ],
    name: "updateDisputeEvent",
    type: "event",
  },
  {
    inputs: [{ internalType: "uint256", name: "_orderId", type: "uint256" }],
    name: "acceptDispute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_orderId", type: "uint256" }],
    name: "acceptOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_address", type: "address" }],
    name: "addAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_orderId", type: "uint256" }],
    name: "cancelOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_orderId", type: "uint256" }],
    name: "completeOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_orderId", type: "uint256" }],
    name: "deliverOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_orderId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "disputeOrder",
    outputs: [
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        internalType: "struct OrderBook.Order",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "fetchMyOrders",
    outputs: [
      { internalType: "uint256[]", name: "", type: "uint256[]" },
      { internalType: "uint256[]", name: "", type: "uint256[]" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_address", type: "address" }],
    name: "getCustomFees",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getDefaultFees",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_address", type: "address" }],
    name: "hasAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_fee", type: "uint256" },
      { internalType: "address", name: "_treasuryAddress", type: "address" },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_seller", type: "address" },
      { internalType: "uint256", name: "_productId", type: "uint256" },
      { internalType: "uint256", name: "_price", type: "uint256" },
      { internalType: "address", name: "_token", type: "address" },
    ],
    name: "newOrder",
    outputs: [
      {
        components: [
          { internalType: "address", name: "buyer", type: "address" },
          { internalType: "address", name: "seller", type: "address" },
          { internalType: "uint256", name: "productId", type: "uint256" },
          { internalType: "uint256", name: "price", type: "uint256" },
          { internalType: "address", name: "token", type: "address" },
          {
            internalType: "enum OrderBook.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "deliveryTimestamp",
            type: "uint256",
          },
        ],
        internalType: "struct OrderBook.Order",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "orderIdCounter",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_orderId", type: "uint256" }],
    name: "rejectOrder",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_address", type: "address" }],
    name: "removeAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_orderId", type: "uint256" },
      { internalType: "uint256", name: "_refundAmount", type: "uint256" },
    ],
    name: "resolveDispute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_address", type: "address" },
      { internalType: "uint256", name: "_platformFee", type: "uint256" },
    ],
    name: "setCustomFee",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_fee", type: "uint256" }],
    name: "setDefaultFee",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "bool", name: "_enable", type: "bool" },
    ],
    name: "setPayableToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "treasuryAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_orderId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "updateDispute",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "withdrawEth",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "withdrawToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
export const ERC20ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "nonpayable",
    type: "function",
  },
];
