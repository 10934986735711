const countries = [
  {
    name: 'Afghanistan',
    code: 'af',
    dial_code: '+93',
  },
  {
    name: 'Albania',
    code: 'al',
    dial_code: '+355',
  },
  {
    name: 'Algeria',
    code: 'dz',
    dial_code: '+213',
  },
  {
    name: 'Andorra',
    code: 'ad',
    dial_code: '+376',
  },
  {
    name: 'Angola',
    code: 'ao',
    dial_code: '+244',
  },
  {
    name: 'Anguilla',
    code: 'ai',
    dial_code: '+1264',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ag',
    dial_code: '+1268',
  },
  {
    name: 'Argentina',
    code: 'ar',
    dial_code: '+54',
  },
  {
    name: 'Armenia',
    code: 'am',
    dial_code: '+374',
  },
  {
    name: 'Aruba',
    code: 'aw',
    dial_code: '+297',
  },
  {
    name: 'Australia',
    code: 'au',
    dial_code: '+61',
  },
  {
    name: 'Austria',
    code: 'at',
    dial_code: '+43',
  },
  {
    name: 'Azerbaijan',
    code: 'az',
    dial_code: '+994',
  },
  {
    name: 'Bahamas',
    code: 'bs',
    dial_code: '+1242',
  },
  {
    name: 'Bahrain',
    code: 'bh',
    dial_code: '+973',
  },
  {
    name: 'Bangladesh',
    code: 'bd',
    dial_code: '+880',
  },
  {
    name: 'Barbados',
    code: 'bb',
    dial_code: '+1246',
  },
  {
    name: 'Belarus',
    code: 'by',
    dial_code: '+375',
  },
  {
    name: 'Belgium',
    code: 'be',
    dial_code: '+32',
  },
  {
    name: 'Belize',
    code: 'bz',
    dial_code: '+501',
  },
  {
    name: 'Benin',
    code: 'bj',
    dial_code: '+229',
  },
  {
    name: 'Bermuda',
    code: 'bm',
    dial_code: '+1441',
  },
  {
    name: 'Bhutan',
    code: 'bt',
    dial_code: '+975',
  },
  {
    name: 'Bolivia',
    code: 'bo',
    dial_code: '+591',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'ba',
    dial_code: '+387',
  },
  {
    name: 'Botswana',
    code: 'bw',
    dial_code: '+267',
  },
  {
    name: 'Brazil',
    code: 'br',
    dial_code: '+55',
  },
  {
    name: 'Brunei Darussalam',
    code: 'bn',
    dial_code: '+673',
  },
  {
    name: 'Bulgaria',
    code: 'bg',
    dial_code: '+359',
  },
  {
    name: 'Burkina Faso',
    code: 'bf',
    dial_code: '+226',
  },
  {
    name: 'Burundi',
    code: 'bi',
    dial_code: '+257',
  },
  {
    name: 'Cambodia',
    code: 'kh',
    dial_code: '+855',
  },
  {
    name: 'Cameroon',
    code: 'cm',
    dial_code: '+237',
  },
  {
    name: 'Canada',
    code: 'ca',
    dial_code: '+1',
  },
  {
    name: 'Cape Verde',
    code: 'cv',
    dial_code: '+238',
  },
  {
    name: 'Cayman Islands',
    code: 'ky',
    dial_code: '+ 345',
  },
  {
    name: 'Central African Republic',
    code: 'cf',
    dial_code: '+236',
  },
  {
    name: 'Chad',
    code: 'td',
    dial_code: '+235',
  },
  {
    name: 'Chile',
    code: 'cl',
    dial_code: '+56',
  },
  {
    name: 'China',
    code: 'cn',
    dial_code: '+86',
  },
  {
    name: 'Christmas Island',
    code: 'cx',
    dial_code: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'cc',
    dial_code: '+61',
  },
  {
    name: 'Colombia',
    code: 'co',
    dial_code: '+57',
  },
  {
    name: 'Comoros',
    code: 'km',
    dial_code: '+269',
  },
  {
    name: 'Congo',
    code: 'cg',
    dial_code: '+242',
  },
  {
    name: 'Cook Islands',
    code: 'ck',
    dial_code: '+682',
  },
  {
    name: 'Costa Rica',
    code: 'cr',
    dial_code: '+506',
  },
  {
    name: "Cote D'Ivoire",
    code: 'ci',
    dial_code: '+225',
  },
  {
    name: 'Croatia',
    code: 'hr',
    dial_code: '+385',
  },
  {
    name: 'Cuba',
    code: 'cu',
    dial_code: '+53',
  },
  {
    name: 'Cyprus',
    code: 'cy',
    dial_code: '+537',
  },
  {
    name: 'Czech Republic',
    code: 'cz',
    dial_code: '+420',
  },
  {
    name: 'Democratic Republic of the Congo',
    code: 'cd',
    dial_code: '+243',
  },
  {
    name: 'Denmark',
    code: 'dk',
    dial_code: '+45',
  },
  {
    name: 'Djibouti',
    code: 'dj',
    dial_code: '+253',
  },
  {
    name: 'Dominica',
    code: 'dm',
    dial_code: '+1767',
  },
  {
    name: 'Dominican Republic',
    code: 'do',
    dial_code: '+1849',
  },
  {
    name: 'Ecuador',
    code: 'ec',
    dial_code: '+593',
  },
  {
    name: 'Egypt',
    code: 'eg',
    dial_code: '+20',
  },
  {
    name: 'El Salvador',
    code: 'sv',
    dial_code: '+503',
  },
  {
    name: 'Equatorial Guinea',
    code: 'gq',
    dial_code: '+240',
  },
  {
    name: 'Eritrea',
    code: 'er',
    dial_code: '+291',
  },
  {
    name: 'Estonia',
    code: 'ee',
    dial_code: '+372',
  },
  {
    name: 'Ethiopia',
    code: 'et',
    dial_code: '+251',
  },
  {
    name: 'Falkland Islands',
    code: 'fk',
    dial_code: '+500',
  },
  {
    name: 'Faroe Islands',
    code: 'fo',
    dial_code: '+298',
  },
  {
    name: 'Federated States of Micronesia',
    code: 'fm',
    dial_code: '+691',
  },
  {
    name: 'Fiji',
    code: 'fj',
    dial_code: '+679',
  },
  {
    name: 'Finland',
    code: 'fi',
    dial_code: '+358',
  },
  {
    name: 'France',
    code: 'fr',
    dial_code: '+33',
  },
  {
    name: 'French Guiana',
    code: 'gf',
    dial_code: '+594',
  },
  {
    name: 'French Polynesia',
    code: 'pf',
    dial_code: '+689',
  },
  {
    name: 'French Southern Territories',
    code: 'tf',
    dial_code: '+262',
  },
  {
    name: 'Gabon',
    code: 'ga',
    dial_code: '+241',
  },
  {
    name: 'Gambia',
    code: 'gm',
    dial_code: '+220',
  },
  {
    name: 'Georgia',
    code: 'ge',
    dial_code: '+995',
  },
  {
    name: 'Germany',
    code: 'de',
    dial_code: '+49',
  },
  {
    name: 'Ghana',
    code: 'gh',
    dial_code: '+233',
  },
  {
    name: 'Gibraltar',
    code: 'gi',
    dial_code: '+350',
  },
  {
    name: 'Great Britain (UK)',
    code: 'gb',
    dial_code: '+44',
  },
  {
    name: 'Greece',
    code: 'gr',
    dial_code: '+30',
  },
  {
    name: 'Greenland',
    code: 'gl',
    dial_code: '+299',
  },
  {
    name: 'Grenada',
    code: 'gd',
    dial_code: '+1473',
  },
  {
    name: 'Guadeloupe',
    code: 'gp',
    dial_code: '+590',
  },
  {
    name: 'Guatemala',
    code: 'gt',
    dial_code: '+502',
  },
  {
    name: 'Guinea',
    code: 'gn',
    dial_code: '+224',
  },
  {
    name: 'Guinea-Bissau',
    code: 'gw',
    dial_code: '+245',
  },
  {
    name: 'Guyana',
    code: 'gy',
    dial_code: '+595',
  },
  {
    name: 'Haiti',
    code: 'ht',
    dial_code: '+509',
  },
  {
    name: 'Honduras',
    code: 'hn',
    dial_code: '+504',
  },
  {
    name: 'Hong Kong',
    code: 'hk',
    dial_code: '+852',
  },
  {
    name: 'Hungary',
    code: 'hu',
    dial_code: '+36',
  },
  {
    name: 'Iceland',
    code: 'is',
    dial_code: '+354',
  },
  {
    name: 'India',
    code: 'in',
    dial_code: '+91',
  },
  {
    name: 'Indonesia',
    code: 'id',
    dial_code: '+62',
  },
  {
    name: 'Iran',
    code: 'ir',
    dial_code: '+98',
  },
  {
    name: 'Iraq',
    code: 'iq',
    dial_code: '+964',
  },
  {
    name: 'Ireland',
    code: 'ie',
    dial_code: '+353',
  },
  {
    name: 'Israel',
    code: 'il',
    dial_code: '+972',
  },
  {
    name: 'Italy',
    code: 'it',
    dial_code: '+39',
  },
  {
    name: 'Jamaica',
    code: 'jm',
    dial_code: '+1876',
  },
  {
    name: 'Japan',
    code: 'jp',
    dial_code: '+81',
  },
  {
    name: 'Jordan',
    code: 'jo',
    dial_code: '+962',
  },
  {
    name: 'Kazakhstan',
    code: 'kz',
    dial_code: '+7 7',
  },
  {
    name: 'Kenya',
    code: 'ke',
    dial_code: '+254',
  },
  {
    name: 'Kiribati',
    code: 'ki',
    dial_code: '+686',
  },
  {
    name: 'North Korea',
    code: 'kp',
    dial_code: '+850',
  },
  {
    name: 'South Korea',
    code: 'kr',
    dial_code: '+82',
  },
  {
    name: 'Kuwait',
    code: 'kw',
    dial_code: '+965',
  },
  {
    name: 'Kyrgyzstan',
    code: 'kg',
    dial_code: '+996',
  },
  {
    name: 'Laos',
    code: 'la',
    dial_code: '+856',
  },
  {
    name: 'Latvia',
    code: 'lv',
    dial_code: '+371',
  },
  {
    name: 'Lebanon',
    code: 'lb',
    dial_code: '+961',
  },
  {
    name: 'Lesotho',
    code: 'ls',
    dial_code: '+266',
  },
  {
    name: 'Liberia',
    code: 'lr',
    dial_code: '+231',
  },
  {
    name: 'Libya',
    code: 'ly',
    dial_code: '+218',
  },
  {
    name: 'Liechtenstein',
    code: 'li',
    dial_code: '+423',
  },
  {
    name: 'Lithuania',
    code: 'lt',
    dial_code: '+370',
  },
  {
    name: 'Luxembourg',
    code: 'lu',
    dial_code: '+352',
  },
  {
    name: 'Macao',
    code: 'mo',
    dial_code: '+853',
  },
  {
    name: 'Macedonia',
    code: 'mk',
    dial_code: '+389',
  },
  {
    name: 'Madagascar',
    code: 'mg',
    dial_code: '+261',
  },
  {
    name: 'Malawi',
    code: 'mw',
    dial_code: '+265',
  },
  {
    name: 'Malaysia',
    code: 'my',
    dial_code: '+60',
  },
  {
    name: 'Maldives',
    code: 'mv',
    dial_code: '+960',
  },
  {
    name: 'Mali',
    code: 'ml',
    dial_code: '+223',
  },
  {
    name: 'Malta',
    code: 'mt',
    dial_code: '+356',
  },
  {
    name: 'Marshall Islands',
    code: 'mh',
    dial_code: '+692',
  },
  {
    name: 'Martinique',
    code: 'mq',
    dial_code: '+596',
  },
  {
    name: 'Mauritania',
    code: 'mr',
    dial_code: '+222',
  },
  {
    name: 'Mauritius',
    code: 'mu',
    dial_code: '+230',
  },
  {
    name: 'Mayotte',
    code: 'yt',
    dial_code: '+262',
  },
  {
    name: 'Mexico',
    code: 'mx',
    dial_code: '+52',
  },
  {
    name: 'Moldova',
    code: 'md',
    dial_code: '+373',
  },
  {
    name: 'Monaco',
    code: 'mc',
    dial_code: '+377',
  },
  {
    name: 'Mongolia',
    code: 'mn',
    dial_code: '+976',
  },
  {
    name: 'Montserrat',
    code: 'ms',
    dial_code: '+1664',
  },
  {
    name: 'Morocco',
    code: 'ma',
    dial_code: '+212',
  },
  {
    name: 'Mozambique',
    code: 'mz',
    dial_code: '+258',
  },
  {
    name: 'Myanmar',
    code: 'mm',
    dial_code: '+95',
  },
  {
    name: 'Namibia',
    code: 'na',
    dial_code: '+264',
  },
  {
    name: 'Nauru',
    code: 'nr',
    dial_code: '+674',
  },
  {
    name: 'Nepal',
    code: 'np',
    dial_code: '+977',
  },
  {
    name: 'Netherlands',
    code: 'nl',
    dial_code: '+31',
  },
  {
    name: 'Netherlands Antilles',
    code: 'an',
    dial_code: '+599',
  },
  {
    name: 'New Caledonia',
    code: 'nc',
    dial_code: '+687',
  },
  {
    name: 'New Zealand',
    code: 'nz',
    dial_code: '+64',
  },
  {
    name: 'Nicaragua',
    code: 'ni',
    dial_code: '+505',
  },
  {
    name: 'Niger',
    code: 'ne',
    dial_code: '+227',
  },
  {
    name: 'Nigeria',
    code: 'ng',
    dial_code: '+234',
  },
  {
    name: 'Niue',
    code: 'nu',
    dial_code: '+683',
  },
  {
    name: 'Norfolk Island',
    code: 'nf',
    dial_code: '+672',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'mp',
    dial_code: '+1670',
  },
  {
    name: 'Norway',
    code: 'no',
    dial_code: '+47',
  },
  {
    name: 'Guernsey',
    code: 'gg',
    dial_code: '+44',
  },
  {
    name: 'Oman',
    code: 'om',
    dial_code: '+968',
  },
  {
    name: 'Pakistan',
    code: 'pk',
    dial_code: '+92',
  },
  {
    name: 'Palau',
    code: 'pw',
    dial_code: '+680',
  },
  {
    name: 'Palestinian Territory',
    code: 'ps',
    dial_code: '+970',
  },
  {
    name: 'Panama',
    code: 'pa',
    dial_code: '+507',
  },
  {
    name: 'Papua New Guinea',
    code: 'pg',
    dial_code: '+675',
  },
  {
    name: 'Paraguay',
    code: 'py',
    dial_code: '+595',
  },
  {
    name: 'Peru',
    code: 'pe',
    dial_code: '+51',
  },
  {
    name: 'Philippines',
    code: 'ph',
    dial_code: '+63',
  },
  {
    name: 'Pitcairn',
    code: 'pn',
    dial_code: '+872',
  },
  {
    name: 'Poland',
    code: 'pl',
    dial_code: '+48',
  },
  {
    name: 'Portugal',
    code: 'pt',
    dial_code: '+351',
  },
  {
    name: 'Qatar',
    code: 'qa',
    dial_code: '+974',
  },
  {
    name: 'Reunion',
    code: 're',
    dial_code: '+262',
  },
  {
    name: 'Romania',
    code: 'ro',
    dial_code: '+40',
  },
  {
    name: 'Russian Federation',
    code: 'ru',
    dial_code: '+7',
  },
  {
    name: 'Rwanda',
    code: 'rw',
    dial_code: '+250',
  },
  {
    name: 'S. Georgia and S. Sandwich Islands',
    code: 'gs',
    dial_code: '+500',
  },
  {
    name: 'Saint Helena',
    code: 'sh',
    dial_code: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'kn',
    dial_code: '+1869',
  },
  {
    name: 'Saint Lucia',
    code: 'lc',
    dial_code: '+1758',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'pm',
    dial_code: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'vc',
    dial_code: '+1784',
  },
  {
    name: 'Samoa',
    code: 'ws',
    dial_code: '+685',
  },
  {
    name: 'San Marino',
    code: 'sm',
    dial_code: '+378',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'st',
    dial_code: '+239',
  },
  {
    name: 'Saudi Arabia',
    code: 'sa',
    dial_code: '+966',
  },
  {
    name: 'Senegal',
    code: 'sn',
    dial_code: '+221',
  },
  {
    name: 'Seychelles',
    code: 'sc',
    dial_code: '+248',
  },
  {
    name: 'Sierra Leone',
    code: 'sl',
    dial_code: '+232',
  },
  {
    name: 'Singapore',
    code: 'sg',
    dial_code: '+65',
  },
  {
    name: 'Slovakia',
    code: 'sk',
    dial_code: '+421',
  },
  {
    name: 'Slovenia',
    code: 'si',
    dial_code: '+386',
  },
  {
    name: 'Solomon Islands',
    code: 'sb',
    dial_code: '+677',
  },
  {
    name: 'Somalia',
    code: 'so',
    dial_code: '+252',
  },
  {
    name: 'South Africa',
    code: 'za',
    dial_code: '+27',
  },
  {
    name: 'Spain',
    code: 'es',
    dial_code: '+34',
  },
  {
    name: 'Sri Lanka',
    code: 'lk',
    dial_code: '+94',
  },
  {
    name: 'Sudan',
    code: 'sd',
    dial_code: '+249',
  },
  {
    name: 'Suriname',
    code: 'sr',
    dial_code: '+597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'sj',
    dial_code: '+47',
  },
  {
    name: 'Swaziland',
    code: 'sz',
    dial_code: '+268',
  },
  {
    name: 'Sweden',
    code: 'se',
    dial_code: '+46',
  },
  {
    name: 'Switzerland',
    code: 'ch',
    dial_code: '+41',
  },
  {
    name: 'Syria',
    code: 'sy',
    dial_code: '+963',
  },
  {
    name: 'Taiwan',
    code: 'tw',
    dial_code: '+886',
  },
  {
    name: 'Tajikistan',
    code: 'tj',
    dial_code: '+992',
  },
  {
    name: 'Tanzania',
    code: 'tz',
    dial_code: '+255',
  },
  {
    name: 'Thailand',
    code: 'th',
    dial_code: '+66',
  },
  {
    name: 'Togo',
    code: 'tg',
    dial_code: '+228',
  },
  {
    name: 'Tokelau',
    code: 'tk',
    dial_code: '+690',
  },
  {
    name: 'Tonga',
    code: 'to',
    dial_code: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'tt',
    dial_code: '+1868',
  },
  {
    name: 'Tunisia',
    code: 'tn',
    dial_code: '+216',
  },
  {
    name: 'Turkey',
    code: 'tr',
    dial_code: '+90',
  },
  {
    name: 'Turkmenistan',
    code: 'tm',
    dial_code: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'tc',
    dial_code: '+1649',
  },
  {
    name: 'Tuvalu',
    code: 'tv',
    dial_code: '+688',
  },
  {
    name: 'Uganda',
    code: 'ug',
    dial_code: '+256',
  },
  {
    name: 'Ukraine',
    code: 'ua',
    dial_code: '+380',
  },
  {
    name: 'United Arab Emirates',
    code: 'ae',
    dial_code: '+971',
  },
  {
    name: 'United States of America',
    code: 'us',
    dial_code: '+1',
  },
  {
    name: 'Uruguay',
    code: 'uy',
    dial_code: '+598',
  },
  {
    name: 'Uzbekistan',
    code: 'uz',
    dial_code: '+998',
  },
  {
    name: 'Vanuatu',
    code: 'vu',
    dial_code: '+678',
  },
  {
    name: 'Venezuela',
    code: 've',
    dial_code: '+58',
  },
  {
    name: 'Vietnam',
    code: 'vn',
    dial_code: '+84',
  },
  {
    name: 'Virgin Islands (British)',
    code: 'vg',
    dial_code: '+1284',
  },
  {
    name: 'Virgin Islands (U.S.)',
    code: 'vi',
    dial_code: '+1340',
  },
  {
    name: 'Wallis and Futuna',
    code: 'wf',
    dial_code: '+681',
  },
  {
    name: 'Western Sahara',
    code: 'eh',
    dial_code: '+212',
  },
  {
    name: 'Yemen',
    code: 'ye',
    dial_code: '+967',
  },
  {
    name: 'Zambia',
    code: 'zm',
    dial_code: '+260',
  },
  {
    name: 'Zimbabwe',
    code: 'zw',
    dial_code: '+263',
  },
];

export default countries;
