<template>
  <div>
    <Dashboard>
      <div class="create-container">
        <div class="flex-row">
          <h2 class="createtitletext flex-row">
            {{ localize('TEXT_CREATE_LISTING') }}
          </h2>
          <div
            class="do-edit"
            @click="queryMyServices"
          >
            {{ localize('TEXT_MY_LISTINGS') }}
          </div>
          <div
            v-if="$route.params.id"
            class="do-edit"
            @click="gotoPath('services/' + $route.params.id)"
          >
            {{ localize('TEXT_VIEW_AS_MEMBER') }}
          </div>
        </div>

        <div class="flex-row-reverse">

          <div class="container">

            <div class="list-type">

              <CDropdown
                :title="localize('TEXT_TYPE')"
                :toggler-text="localize(listType)"
                class="create-dropdown-item"
              >
                <CDropdownItem
                  v-for="i in Object.keys(listings)"
                  :key="i"
                  @click.native="listType = i; category = undefined; subCategory = undefined"
                >
                  {{ localize(i) }}
                </CDropdownItem>
              </CDropdown>

              <CDropdown
                v-if="listType === 'LOCALS' || listType === 'STORES'"
                :title="localize('TEXT_CATEGORY')"
                :toggler-text="localize(category)"
                class="create-dropdown-item"
              >
                <CDropdownItem
                  v-for="(c,i) in listings[listType]"
                  :key="i"
                  @click.native="category = i; subCategory = undefined"
                >
                  {{ localize(i) }}
                </CDropdownItem>
              </CDropdown>

              <CDropdown
                v-else
                :title="localize('TEXT_CATEGORY')"
                :toggler-text="localize(category)"
                class="create-dropdown-item"
              >
                <CDropdownItem
                  v-for="(c,i) in listings[listType]"
                  :key="i"
                  @click.native="category = c; subCategory = undefined"
                >
                  {{ localize(c) }}
                </CDropdownItem>
              </CDropdown>

              <div v-if="listings[listType]">
                <CDropdown
                  v-if="listings[listType][category] instanceof Array"
                  :title="localize('TEXT_SUB_CATEGORY')"
                  :toggler-text="localize(subCategory)"
                  class="create-dropdown-item"
                >
                  <CDropdownItem
                    v-for="(c,i) in listings[listType][category]"
                    :key="i"
                    @click.native="category = c"
                  >
                    {{ localize(c) }}
                  </CDropdownItem>
                </CDropdown>
              </div>

            </div>

            <Transition
              name="route"
              mode="out-in"
            >

              <div>
                <div
                  v-if="listType === 'SERVICES'"
                >

                  <CreateService
                    :profile="profile"
                    :product="listing"
                    :listType="listType"
                    :category="category"
                    :subCategory="subCategory"
                  />

                </div>

                <div
                  v-if="listType === 'JOBS'"
                >

                </div>

                <div
                  v-if="listType === 'LOCALS'"
                >

                </div>

                <div
                  v-if="listType === 'STORES'"
                >

                </div>
              </div>

            </Transition>

          </div>

        </div>

        <CModal
          v-if="showProfileModal"
          width="medium"
          color="#f9b115"
          title="Please Wait"
          @close="showProfileModal = false"
        >
          <template #header>
            <div> {{ localize('TEXT_PROFILE') }} </div>
          </template>
          <template #body>
            <div>
              <Profile
                isMin="true"
                @close="showProfileModal = false"
              />
            </div>
          </template>
          <template #footer>
            <div class="hidden" />
          </template>
        </CModal>
      </div>
    </Dashboard>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import Axios from "axios";
import FormData from "form-data";
import CreateService from "./CreateService.vue"
import {ERC20FactoryABI} from "../abi.js";
import Profile from "./Profile.vue";

export default {
    "name": "List",
    "components": {
        Profile,
        CreateService
    },
    setup () {

        const translation = translationStore(),
            serverConfig = toRefs(inject("serverConfig")),
            route = inject("route"),
            router = inject("router"),
            userInfo = inject("userInfo"),
            icon = iconStore(),
            web3 = inject("web3"),
            showProfileModal = ref(false),
            profile = ref(),
            listType = ref(),
            listing = ref(),
            listings = ref({

                "SERVICES": [
                    "SERVICE_AI_SERVICES",
                    "SERVICE_AI_DATA_SCIENCE",
                    "SERVICE_SOFTWARE_SERVICES",
                    "SERVICE_TECHNOLOGY_SERVICES",
                    "SERVICE_MARKETING_SALES",
                    "SERVICE_CAD_ARCHITECTURE",
                    "SERVICE_MEDIA_DESIGN",
                    "SERVICE_MUSIC_AUDIO",
                    "SERVICE_FILM_TV",
                    "SERVICE_CONTENT_CREATION",
                    "SERVICE_LEARNING_EDUCATION",
                    "SERVICE_LANGUAGES_TRANSLATION",
                    "SERVICE_BUSINESS_FINANCE",
                    "SERVICE_LEGAL_ACCOUNTING",
                    "SERVICE_OTHER"
                ]
/*
                "JOBS": [
                    "JOBS_AI_ENGINEER",
                    "JOBS_DATA_SCIENTIST",
                    "JOBS_SOFTWARE_ENGINEERING",
                    "JOBS_ENGINEERING_ARCHITECTURE",
                    "JOBS_DIGITAL_MARKETING",
                    "JOBS_GRAPHIC_DESIGN",
                    "JOBS_PRODUCT_DESIGN",
                    "JOBS_MUSIC_AUDIO",
                    "JOBS_FILM_TV",
                    "JOBS_CONTENT_CREATION",
                    "JOBS_SALES",
                    "JOBS_EDUCATION_ACADEMIA",
                    "JOBS_BUSINESS_DEVELOPMENT",
                    "JOBS_PROJECT_MANAGEMENT",
                    "JOBS_CUSTOMER_SERVICE",
                    "JOBS_C_LEVEL_SVP",
                    "JOBS_HR_RECRUITING",
                    "JOBS_LANGUAGES_TRANSLATION",
                    "JOBS_LEGAL",
                    "JOBS_FINANCE_ACCOUNTING",
                    "JOBS_OTHER"
                ],
                "LOCALS": {
                    "LOCALS_HOME_PROPERTY": [
                        "PLUMBING",
                        "ELECTRICAL_WORK",
                        "CARPENTRY",
                        "PAINTING",
                        "ROOFING_SOLAR",
                        "CONSTRUCTION",
                        "INTERIOR_DESIGN",
                        "LANDSCAPING_GARDENING",
                        "HANDYMAN_SERVICES",
                        "HVAC",
                        "SKILLED_TRADE",
                        "RESIDENTIAL_CLEANING",
                        "COMMERCIAL_CLEANING",
                        "WINDOW_CLEANING",
                        "FABRIC_CLEANING",
                        "MANAGEMENT_LEGAL"
                    ],
                    "LOCALS_AUTO": [
                        "AUTO_SALES",
                        "AUTO_PARTS",
                        "AUTO_CLEANING",
                        "AUTO_REPAIR",
                        "AUTO_SERVICES"
                    ],
                    "LOCALS_FOR_SALE": [
                        "APPLIANCES",
                        "FURNITURE",
                        "ELECTRONICS",
                        "CLOTHING",
                        "SPORTING_GOODS",
                        "TOOLS",
                        "BOOKS",
                        "MISCELLANEOUS"
                    ],
                    "LOCALS_HOSPITALITY": [
                        "HOTELS",
                        "RESTAURANTS",
                        "CAFES",
                        "BARS",
                        "EVENT_VENUES",
                        "HOSTING_SERVICES"
                    ],
                    "LOCALS_FOOD_AND_CATERING": [
                        "CATERING",
                        "BAKERIES",
                        "FOOD_TRUCKS",
                        "HOME_CHEFS",
                        "MEAL_PREP",
                        "SPECIALTY_FOOD",
                        "EVENT_CATERING"
                    ],
                    "LOCALS_TRANSPORTATION": [
                        "TAXI_SERVICES",
                        "RIDE_SHARING",
                        "MOVING_SERVICES",
                        "COURIER_DELIVERY",
                        "CAR_RENTAL"
                    ],
                    "LOCALS_HEALTH_FITNESS": [
                        "PERSONAL_TRAINING",
                        "GYMS",
                        "NUTRITIONISTS",
                        "YOGA_INSTRUCTORS",
                        "PHYSICAL_THERAPY",
                        "MASSAGE_THERAPY"
                    ],
                    "LOCALS_EVENTS": [
                        "WEDDINGS",
                        "BIRTHDAY_PARTIES",
                        "CORPORATE_EVENTS",
                        "CONFERENCES",
                        "FUNDRAISERS",
                        "ENTERTAINMENT_BOOKING",
                        "EVENT_PLANNING"
                    ],
                    "LOCALS_LOCAL_MARKETING": [
                        "SEO_SERVICES",
                        "SOCIAL_MEDIA_MANAGEMENT",
                        "EMAIL_MARKETING",
                        "CONTENT_WRITING",
                        "AD_CAMPAIGN_MANAGEMENT"
                    ],
                    "LOCALS_EDUCATION_TUTORING": [
                        "TUTORING",
                        "LANGUAGE_CLASSES",
                        "MUSIC_CLASSES",
                        "ART_CLASSES",
                        "MATH_SCIENCE_TUTORING",
                        "ONLINE_COURSES",
                        "TEST_PREPARATION"
                    ],
                    "LOCALS_SOCIAL_SERVICES": [
                        "ELDERLY_CARE",
                        "CHILDCARE",
                        "PET_CARE",
                        "MENTAL_HEALTH_SUPPORT",
                        "DISABILITY_SERVICES",
                        "COMMUNITY_OUTREACH"
                    ],
                    "LOCALS_TECHNOLOGY_SERVICES": [
                        "IT_SUPPORT",
                        "NETWORK_SETUP",
                        "SOFTWARE_INSTALLATION",
                        "HARDWARE_REPAIR",
                        "CYBERSECURITY",
                        "TECH_CONSULTING"
                    ]
                },
                "STORES": {
                    "STORES_WOMENS_CLOTHING": [
                        "DRESSES",
                        "TOPS",
                        "BOTTOMS",
                        "OUTERWEAR",
                        "ACCESSORIES",
                        "SHOES",
                        "BAGS",
                        "LINGERIE"
                    ],
                    "STORES_MENS_CLOTHING": [
                        "SHIRTS",
                        "PANTS",
                        "SUITS",
                        "JACKETS",
                        "ACCESSORIES",
                        "SHOES",
                        "SPORTSWEAR",
                        "UNDERWEAR"
                    ],
                    "STORES_JEWELRY": [
                        "NECKLACES",
                        "BRACELETS",
                        "EARRINGS",
                        "RINGS",
                        "WATCHES",
                        "BROOCHES",
                        "CUSTOM_JEWELRY",
                        "WEDDING_JEWELRY"
                    ],
                    "STORES_COMPUTERS_LAPTOPS": [
                        "DESKTOP_COMPUTERS",
                        "LAPTOPS",
                        "TABLETS",
                        "MONITORS",
                        "ACCESSORIES",
                        "PRINTERS",
                        "COMPUTER_PARTS"
                    ],
                    "STORES_ELECTRONICS": [
                        "SMARTPHONES",
                        "HOME_ENTERTAINMENT",
                        "CAMERAS",
                        "DRONES",
                        "AUDIO_EQUIPMENT",
                        "VIDEO_EQUIPMENT",
                        "GAMING_CONSOLES",
                        "SMART_HOME_DEVICES"
                    ],
                    "STORES_FURNITURE": [
                        "LIVING_ROOM_FURNITURE",
                        "BEDROOM_FURNITURE",
                        "OFFICE_FURNITURE",
                        "OUTDOOR_FURNITURE",
                        "STORAGE_FURNITURE",
                        "LIGHTING",
                        "DECOR"
                    ],
                    "STORES_HOME_KITCHEN": [
                        "COOKWARE",
                        "DINING_WARE",
                        "SMALL_KITCHEN_APPLIANCES",
                        "FOOD_STORAGE",
                        "KITCHEN_TOOLS",
                        "CUTLERY",
                        "BAKING_SUPPLIES",
                        "TABLEWARE"
                    ],
                    "STORES_BEAUTY_CARE": [
                        "MAKEUP",
                        "SKINCARE",
                        "HAIRCARE",
                        "FRAGRANCES",
                        "NAIL_CARE",
                        "PERSONAL_CARE",
                        "BEAUTY_TOOLS"
                    ],
                    "STORES_PET_SUPPLIES": [
                        "PET_FOOD",
                        "PET_TOYS",
                        "PET_GROOMING",
                        "PET_BEDDING",
                        "PET_HEALTHCARE",
                        "PET_ACCESSORIES",
                        "AQUARIUM_SUPPLIES"
                    ],
                    "STORES_TRAVEL": [
                        "LUGGAGE",
                        "TRAVEL_ACCESSORIES",
                        "TRAVEL_BAGS",
                        "TRAVEL_GEAR",
                        "CAMPING_EQUIPMENT",
                        "OUTDOOR_RECREATION",
                        "TRAVEL_GUIDES"
                    ],
                    "STORES_OUTDOORS": [
                        "CAMPING_GEAR",
                        "HIKING_EQUIPMENT",
                        "FISHING_GEAR",
                        "HUNTING_EQUIPMENT",
                        "GARDENING_TOOLS",
                        "OUTDOOR_FURNITURE",
                        "BBQ_GRILLS",
                        "OUTDOOR_CLOTHING"
                    ],
                    "STORES_ANTIQUES": [
                        "FURNITURE_ANTIQUES",
                        "DECORATIVE_ARTS",
                        "JEWELRY_ANTIQUES",
                        "VINTAGE_CLOTHING",
                        "COLLECTIBLES",
                        "COINS",
                        "BOOKS_ANTIQUES",
                        "CERAMICS"
                    ],
                    "STORES_MISC": [
                        "TOYS",
                        "SPORTS_EQUIPMENT",
                        "OFFICE_SUPPLIES",
                        "BOOKS",
                        "MUSIC_INSTRUMENTS",
                        "ART_SUPPLIES",
                        "TOOLS",
                        "GIFT_ITEMS"
                    ]
                }
*/
            }),
            category = ref(),
            subCategory = ref(),
            showTokenModal = ref(false),
            productMedia = reactive([]),
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            queryMyServices = function () {

                router.push({

                  path: '/search/query',
                  query: { userId: userInfo.user_id }

                }).catch((err) => {

                  throw err;

                });

            },
            getProfile = function (user_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getProfile`,
                    {

                        "params": {

                            userId:user_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                profile.value = response.data;

                                if (!profile.value.name) {

                                    showProfileModal.value = true;

                                }

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getListing = async function (callback) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getListing`,
                    {

                        "params": {

                            // note that product category is subCategory
                            // category is just services,jobs etc
                            listType: route.params.listType.toUpperCase(),
                            id: route.params.id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            listing.value = response.data;

                        }
console.log("List.vue")
console.log(listing.value)

                        if (response.data.type) {

                            // listType.value = route.params.listType.toUpperCase();
                            listType.value = response.data.type;

                        }
console.log("listType.value")
console.log(listType.value)

                        if (response.data.category) {

                            category.value = response.data.category;

                        }

                        if (response.data.subcategory) {

                            subCategory.value = response.data.subCategory;

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            };



        onMounted(() => {

            setTimeout(()=> {

                if (userInfo.authorized === true) {

                    getProfile(userInfo.user_id);

                } else {

                    gotoPath('/');

                }

                if (!listType.value) {

                    listType.value = 'SERVICES';

                }

            }, 1000);

            if (route.params.id) {

                getListing(route.params.id);

            }

            watch(
                serverConfig.chainId,
                () => {

                }
            );

        });

        return {"localize": translation.localize,
            icon,
            web3,
            showTokenModal,
            showProfileModal,
            queryMyServices,
            profile,
            gotoPath,
            listType,
            listing,
            listings,
            getListing,
            category,
            subCategory,
            userInfo,
            serverConfig,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.define-left {
}
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.create-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.create-upload-box {
  height: 100px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  opacity: 1.0;
  cursor: pointer;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-token-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-token-preview-bg:hover {
  opacity: 0.5;
}
.create-token-container {
  padding: 20px;
  width: 33%;
}
.create-token-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.token-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-token-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-token-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-token-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
.flex-row-reverse {
  display: flex;
}
.list-type {
  display: flex;
}
.create-dropdown-item {
  margin-right: 10px;
}
input#create-file-upload {
  display: none;
}
label.create-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.do-edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 13px 10px 0px 20px;
  font-size: 0.8em;
  font-weight: bold;
  color: #505050;
}
.do-edit:hover {
  cursor: pointer;
}
</style>
