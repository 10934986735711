<template>
  <div
    v-if="isLang('ja') > -1"
    :class="docMain"
  >
    <head>
      <title></title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div>
    </div>
  </div>
  <div
    v-else
    :class="docMain"
  >
    <head>
      <title>Thumpr Privacy Policy.</title>
    </head>
    <div>
      <h1 class="doc">
        Personal Information Protection Policy
      </h1>
      At thumpr.xyz, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect your information when you access or use our online marketplace platform.
      <br>
      <br>
By using our website and services, you agree to the practices outlined in this policy.
      <br>
      <br>
<h2>1. Information We Collect</h2>
      <br>
We do not collect any personal data beyond what you voluntarily provide. The only information we store is:
      <br>
<ul>
<li>
Public Profile Data: Information you voluntarily upload to your public profile, such as your name, contact information, and any other details you choose to share with the marketplace community.
</li>
<li>
Gig Information: Any data you upload when creating or managing your service listings (gigs) on thumpr.xyz.
</li>
</ul>
      <br>
      <br>
We do not collect any tracking information such as browsing history, IP addresses, or other data commonly used for tracking users across websites.
      <br>
      <br>
<h2>
2. Cookies
</h2>
      <br>
We use cookies only to facilitate the login process on thumpr.xyz. These cookies are necessary for your session and allow you to access your account. No tracking cookies or third-party cookies are used to monitor your activities.
      <br>
<h2>
3. Data Retention
</h2>
      <br>
We do not store or retain customer data beyond what is necessary for the operation of our platform. We do not keep records of your transactions or other personal information after it is no longer needed for the purposes it was provided.
      <br>
<h2>
4. Data Deletion
</h2>
      <br>
You have the right to request the deletion of your data at any time. If you wish to have your data deleted, please contact us at [Insert contact email], and we will promptly remove all your personal information from our platform, including your profile and gig information.
      <br>
<h2>
5. Smart Contracts and Cryptocurrency Payments
</h2>
      <br>
While we facilitate payments using cryptocurrency and smart contracts for decentralization, these technologies do not involve the collection or storage of personal information by thumpr.xyz. All transactions are handled on the blockchain, and we do not have access to the details of your transactions.
      <br>
<h2>
6. Third-Party Services
</h2>
      <br>
thumpr.xyz does not share or sell your data to any third-party services. We do not use external analytics tools or tracking services. Any third-party services you engage with on our platform, such as payment processors or blockchain wallets, are subject to their own privacy policies.
      <br>
<h2>
7. Security
</h2>
      <br>
We take reasonable precautions to protect the security of your data. However, please note that no method of data transmission or storage is entirely secure, and we cannot guarantee the absolute security of any information you transmit to us.
      <br>
<h2>
8. Changes to This Privacy Policy
</h2>
<br>
We may update this Privacy Policy from time to time to reflect changes in our practices or services. Any changes will be posted on this page, and the "Effective Date" will be updated accordingly. We encourage you to review this policy periodically.
<br>
<h2>
9. Contact Us
</h2>
      <br>
If you have any questions or concerns regarding this Privacy Policy or your data, please contact us at:
      <br>
      <br>
contact@thumpr.xyz
      <br>
    </div>
  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Privacy",
    "components": {
    },
    "props": {
    },
    setup () {

        const icon = iconStore(),
            serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            "icon": icon.get,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  min-height: 2000px;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  min-height: 2000px;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  min-height: 2000px;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  min-height: 2000px;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  line-height: 50px;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
</style>
