<template>
  <div
    class="modal-dialog"
  >
    <LocalizedContent ref="lc" />
    <div class="flex-col">
      <input
        v-model="email"
        class="emailpass"
        placeholder="e-mail"
      >
      <input
        v-model="username"
        class="emailpass"
        placeholder="username"
        type="username"
      >
    </div>
    <br>
    <div class="flex-row">
      <div
        class="accept-button margin-top"
        @click="setUserEmail()"
      >
         {{localize('TEXT_SET')}}
      </div>
    </div>
    <span
      id="message"
      class="success"
    >
      {{ message }}
    </span>
  </div>
</template>
<script>

import {emit, inject, onMounted, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";
import Axios from "axios";

export default {
    "name": "Login",
    "components": {
    },
    "props": {
    },
    "emits": [
        "hide-modal-event",
        "get-userinfo-event"
    ],
    setup (_, {emit}) {

        const emitHideModal = () => {

                emit(
                    "hide-modal-event",
                    false
                );

            },
            emitGetUserInfo = () => {

                emit(
                    "get-userinfo-event",
                    true
                );

            },
            email = ref(""),
            message = ref(""),
            emailOK = ref(false),
            emailAvailable = ref(false),
            username = ref(""),
            usernameOK = ref(false),
            registerClass = ref("send-inactive"),
            loginClass = ref("send-inactive"),
            loginText = ref(""),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            setUserEmail = function () {


                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}setEmail`,
                    {

                        "params": {

                            name:username.value,
                            email:email.value

                        }

                    }

                ).
                    then((response) => {

console.log("response")
console.log(response)

                        if (response.status === 200) {

                            document.getElementById("message").className = "success";
                            message.value = translation.localize("APP_SIGN_UP_COMPLETE");
                            setTimeout(

                                () => {

                                    close();

                                },
                                1000
                            );

                        } else {

                            document.getElementById("message").className = "error";
                            message.value = translation.localize("CONTACT_ERROR");

                        }

                    }).
                    catch((error) => {

                        console.log(error);

                    });

            },
            verifyEmail = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}verifyUserEmail`,
                    {
                        "params": {
                            "email": email.value
                        }
                    }
                ).
                    then((response) => {

                        if (response.data) {

                            if (response.data.email === "available") {

                                document.getElementById("message").className = "success";
                                message.value = translation.localize("LOGIN_EMAIL_AVAILABLE");
                                emailAvailable.value = true;

                            } else {

                                document.getElementById("message").className = "error";
                                emailAvailable.value = false;
                                message.value = translation.localize("LOGIN_ALREADY_REGISTERED_EMAIL");

                            }
                            validateForm();

                        }

                    }).
                    catch((error) => {

                        console.log(error);

                    });

            },
            close = function () {

                emitGetUserInfo();
                emitHideModal();

            },
            validateEmail = function () {

                const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (email.value.match(re)) {

                    emailOK.value = true;
                    verifyEmail();

                } else {

                    emailOK.value = false;
                    message.value = translation.localize("LOGIN_INVALID_EMAIL");

                }

            },
            validateUsername = function () {

                const regex = new RegExp("[!@#$%^&*(),.?\":{}|<>_\\-\\[\\]\\\\/`~;']");

                if (username.value.length > 3 
                    && username.value.length < 22
                    && regex.test(username.value == false)) {

                    usernameOK.value = true;

                } else {

                    usernameOK.value = false;

                }
                validateForm();

            },
            validateForm = function () {

                if (emailAvailable.value == true && emailOK.value == true && usernameOK.value == true) {

                    registerClass.value = "send-active";
                    loginClass.value = "send-inactive";

                } else if (emailAvailable.value == false && emailOK.value == true && usernameOK.value == true) {

                    registerClass.value = "send-inactive";
                    loginClass.value = "send-active";

                } else {

                    registerClass.value = "send-inactive";
                    loginClass.value = "send-inactive";

                }

            };
        onMounted(() => {

            const count = ref(0);
            watch(
                email,
                () => {

                    validateEmail();

                }
            );
            watch(
                username,
                () => {

                    validateUsername();

                }
            );

        });
        return {"localize": translation.localize,
            close,
            email,
            username,
            message,
            email,
            emailOK,
            emailAvailable,
            username,
            usernameOK,
            registerClass,
            loginClass,
            loginText,
            userInfo,
            validateEmail,
            validateForm,
            validateUsername,
            verifyEmail,
            setUserEmail,
            emitHideModal,
            emitGetUserInfo};

    }
};

</script>
<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
h2 {
  margin-left: 20px;
  text-align: left;
  font-size: 24px;
  color: white
}
.content-container {
  margin-top: 150px;
}
.modal-dialog {
  pointer-events: all;
}
/*
.ud-login {
  margin-bottom: 20px;
  padding: 10px;
  flex-wrap: nowrap;
  justify-content: center;
  border: 1px solid #a7a5ff;
  border-radius: 10px;
}
.ud-login:hover {
  cursor: pointer;
}
.ud-logo {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  background-image: url("../assets/ud-logo-default-32.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.ud-logo:hover {
  background-image: url("../assets/ud-logo-hover-32.png");
}
.ud-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
*/
li.home-head {
  text-align: left;
  font-size: 1.1em;
  color: white
}
input.emailpass::placeholder{
  color: #a13aff;
}
input.emailpass {
  line-height: 30px;
  font-size: 0.8em;
  text-indent: 5px;
  padding: 5px;
  width: auto;
  margin: 5px;
  border: none;
  border-bottom: 1px solid #a046ff;
  background-color: #fafafa;
  outline: 0;
}
.home-list {
  width: 250px;
}
.signup {
  font-size: 0.8em;
  width: 100px;
  margin-top: 10px;
  margin-left: 20px;
  color: white;
  height: 50px;
  font-weight: bold;
  background-color: #bf8505;
}
.send-inactive {
  float: left;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #ff7dcb;
  color: #ff7dcb;
  font-size: 0.8em;
  font-weight: bold;
}
.send-active {
  float: left;
  cursor: pointer;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #e10087;
  background-color: #e10087;
  font-weight: bold;
  color: #fff;
  font-size: 0.8em;
}
.signupmodal {
  margin-top: 10%;
}
.error {
  float: left;
  margin: 15px;
  color: grey;
}
.success {
  float: left;
  margin: 15px;
  color: #e10087;
}
.accept-button {
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  background: linear-gradient(145deg, #c4ae5e, #f18742, #ed9467, #edd17e);
  text-align: center;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  width: 100px;
  font-size: .9em;
  cursor: pointer;
}
/*
.ud-text {
  padding: 5px;
  color: black;
}
*/
#message {
  font-size: 0.8em;
  margin: 15px;
  transition: all 500ms ease-in-out;
}
</style>
