<template>
  <div>

    <div class="orders-container">

      <div class="flex-col-grow" v-if="route.params.id">

<!--
        <div class="back-to-orders">
          <div v-html="getRawIcon('ARROW_LEFT_DARK')"></div> <div> {{localize('BACK_TO_ORDERS')}} </div>
        </div>
-->

        <div class="flex-col">
          <OrderCard
            v-if="selectedOrder.order_id"
            class="order-card"
            layout="vertical"
            :order="selectedOrder"
          >
          </OrderCard>
          <Messenger>
          </Messenger>
        </div>

      </div>
      <div v-else class="tab-selector">

        <div class="tabs">
          <div
            v-for="(tab, index) in tabs"
            :key="tab"
            class="tab"
            :class="{ active: selectedTab === tab }"
            @click="selectTab(tab)"
          >
            {{ tab }}
          </div>
          <div
            class="indicator"
            :style="{ transform: `translateX(${activeIndex * 100}%)` }"
          ></div>
        </div>

        <div class="tab-content-wrapper">
          <Transition name="gnarly-slide">
            <div
              v-show="selectedTab === 'open'"
              class="tab-content"
              :style="{ transitionDelay: k * 300 + 'ms' }"
            >
              <OrderCard
                v-for="(i,k) in open"
                class="order-card"
                @reload-orders="getMyOrders"
                :order="i"
              >
              </OrderCard>
              <div v-if="open.length == 0" class="no-orders">
                <h1>{{localize('TEXT_NO_OPEN_ORDERS')}}</h1>
              </div>
            </div>
          </Transition>
          <Transition
            name="gnarly-slide"
          >
            <div
              v-show="selectedTab === 'requests'"
              class="tab-content"
              :style="{ transitionDelay: k * 300 + 'ms' }"
            >
              <OrderCard
                v-for="(i,k) in requests"
                class="order-card"
                @reload-orders="getMyOrders"
                :order="i"
              >
              </OrderCard>
              <div v-if="requests.length == 0" class="no-orders">
                <h1>{{localize('TEXT_NO_ORDER_REQUESTS')}}</h1>
              </div>
            </div>
          </Transition>
          <Transition name="gnarly-slide">
            <div
              v-show="selectedTab === 'disputes'"
              class="tab-content"
              :style="{ transitionDelay: k * 300 + 'ms' }"
            >
              <OrderCard
                v-for="(i,k) in disputed"
                class="order-card"
                @reload-orders="getMyOrders"
                :order="i"
              >
              </OrderCard>
              <div v-if="disputes.length == 0" class="no-orders">
                <h1>{{localize('TEXT_NO_ORDER_DISPUTES')}}</h1>
              </div>
            </div>
          </Transition>
          <Transition name="gnarly-slide" tag="div">
            <div
              v-show="selectedTab === 'completed'"
              class="tab-content"
              :style="{ transitionDelay: k * 300 + 'ms' }"
            >
              <OrderCard
                v-for="(i,k) in completed"
                class="order-card"
                @reload-orders="getMyOrders"
                :order="i"
              >
              </OrderCard>
              <div v-if="completed.length == 0" class="no-orders">
                <h1>{{localize('TEXT_NO_COMPLETED_ORDERS')}}</h1>
              </div>
            </div>
          </Transition>
        </div>
      </div>

    </div>

    <Transition>
      <CModal
        v-if="showAddModal"
        color="#f9b115"
        title="Please Wait"
        @close="showAddModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_ADD_SERVICE') }} </div>
        </template>
        <template #body>
          <div>
            {{ localize(showAddMessage) }}
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, computed, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import {QuillEditor} from "@vueup/vue-quill";
import Axios from "axios";
import FormData from "form-data";
import OrderCard from "./OrderCard.vue";
import Messenger from "./Messenger.vue";
import {ERC20FactoryABI} from "../abi.js";
import countries from "../countries.ts";

export default {
    "name": "Orders",
    "components": {
      OrderCard,
      Messenger
    },
    "props": {
    },
    setup (props) {

        const translation = translationStore(),
            serverConfig = toRefs(inject("serverConfig")),
            route = inject("route"),
            router = inject("router"),
            web3 = inject("web3"),
            userInfo = inject("userInfo"),
            icon = iconStore(),
            showAddModal = ref(),
            product = ref({}),
            open = ref([]),
            requests = ref([]),
            disputes = ref([]),
            completed = ref([]),
            profile = ref(),
            tabs = ref(['open', 'requests', 'disputes', 'completed']),
            orderStatus = ([
              "Proposed", // requested
              "Accepted", // open
              "Cancelled", // completed
              "Rejected", // completed
              "Delivered", // open
              "Completed", // completed
              "Disputed" // disputed
            ]),
            selectedTab = ref('open'),
            selectTab = (tab) => {

               selectedTab.value = tab;

            },
            activeIndex = computed(() => tabs.value.indexOf(selectedTab.value)),
            genRandStr = function () {

                return (new Date().getSeconds() * Math.random()).toString(36).replace(
                    ".",
                    ""
                );

            },
            videoTypes = reactive([
                "video/mp4",
                "video/mpeg",
                "video/x-msvideo",
                "video/webm"
            ]),
            imageTypes = reactive([
                "image/gif",
                "image/jpeg",
                "image/avif",
                "image/png",
                "image/svg",
                "image/svg+xml",
                "image/webp"
            ]),
            maxVideoSize = ref("1000000000"),
            maxImageSize = ref("10000000"),
            selectedOrder = ref({}),
            getMyOrders = async function (callback) {

/*
                const accounts = await ethereum.request({"method": "eth_requestAccounts"}),
                    addr = accounts[0];
*/
                const accounts = await web3.value.getAccounts();
                const addr = accounts[0];

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getMyOrders`,
                    {

                        "params": {

                            addr

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            open.value.splice(0);
                            requests.value.splice(0);
                            completed.value.splice(0);
                            disputes.value.splice(0);

                            for (var i in response.data) {

                                if (response.data[i].order_id == route.params.id) {

                                    selectedOrder.value = response.data[i];

                                }

                                switch (parseInt(response.data[i].status_)) {
                                    case 0:
                                        requests.value.push(response.data[i])
                                        break;
                                    case 1:
                                        open.value.push(response.data[i])
                                        break;
                                    case 2:
                                        completed.value.push(response.data[i])
                                        break;
                                    case 3:
                                        completed.value.push(response.data[i])
                                        break;
                                    case 4:
                                        open.value.push(response.data[i])
                                        break;
                                    case 5:
                                        completed.value.push(response.data[i])
                                        break;
                                    case 6:
                                        disputes.value.push(response.data[i])
                                        break;
                                    default:

                                }

                            }

                            if (requests.value.length > 0) {

                                selectTab('requests');

                            } else if (disputes.value.length > 0) {

                                selectTab('disputes');

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoUrl = function (url) {

                window.open(url);

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            getProfile = function (user_id) {

                if (!user_id)
                  return;

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getProfile`,
                    {

                        "params": {

                            userId:user_id

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            if (response.data) {

                                profile.value = response.data;

                            }

                            console.log("profile.value")
                            console.log(profile.value)
                            console.log(profile.value.geoip)

                            var geo = "";
                            try {

                                geo = JSON.parse(profile.value.geoip)

                            } catch(e) {

                                console.log(e);

                            }

                            if (geo.country) {

                                console.log(geo.country)

                                for (var i=0; i < countries.length; i++) {
                                  
                                    if (countries[i].code.toUpperCase() == geo.country.toUpperCase()) {

                                        product.value.country = countries[i].name;

                                    }

                                }

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            updateView = function (i) {

                console.log('CreateServices.vue updateView ' + i)

                if (serverConfig.view === "desktop") {

                } else if (serverConfig.view === "tablet") {

                } else {

                }

            };

        onMounted(() => {

            console.log("serverConfig")
            console.log(serverConfig)

            getProfile(userInfo.user_id);
            getMyOrders();

            watch(
                serverConfig.chainId,
                () => {

                }
            );

/*
            watch(
                route.params.id,
                () => {

                    selectedOrder.value = route.params.room;

                }
            );
*/

            watch(
                serverConfig.view.value,
                () => {

                    console.log("Orders.vue: serverConfig.view " + serverConfig.view)

                }
            );

        });

        return {"localize": translation.localize,
            icon,
            web3,
            route,
            tabs,
            selectTab,
            selectedTab,
            selectedOrder,
            activeIndex,
            countries,
            updateView,
            getURL,
            getProfile,
            getMyOrders,
            gotoPath,
            gotoUrl,
            getRawIcon,
            genRandStr,
            showAddModal,
            userInfo,
            profile,
            open,
            orderStatus,
            requests,
            disputes,
            completed,
            serverConfig,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.define-left {
}
.margin-top {
  margin-top: 20px;
}
.titlecolor {
  color: #22255C;
}
.paragraph {
  margin: 10px;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.text {
  color: #908ab9;
}
.createtitletext {
  color: #000;
}
.sub-title {
  color: #000;
  font-size: 1.2em;
}
.create-sub-header {
  margin-top: 20px;
  text-align: left;
  font-weight: bold;
  line-height: 35px;
  color: #22255C;
  font-size: 0.9em;
}
.sub-script {
  text-align: left;
  font-weight: bold;
  color: #afafaf;
  line-height: 30px;
  font-size: 0.8em;
}
.create-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.create-upload-box {
  height: 20px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 1px;
  margin-bottom: 1px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box {
  height: 100px;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  background: url("../assets/upload-icon.svg") #efefef;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.create-upload-box:hover {
  text-decoration: underline;
  opacity: 1.0;
  cursor: pointer;
}
.create-upload-box-thumbnail {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: grey;
  font-size: 2em;
  border: 1px solid #efefef;
  border-radius: 15px;
}
.create-upload-box-thumbnail:hover {
  text-decoration: underline;
  opacity: 1.0;
  cursor: pointer;
}
.add-media-thumbnail {
  font-size: 2em;
  color: #575757;
  font-size: 2em;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
.remove-button {
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #f7608a;
}
.create-order-preview-bg {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 3px dashed #d295ff;
}
.create-order-preview-bg:hover {
  text-decoration: underline;
  opacity: 0.5;
}
.create-order-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 300px;
}
.create-order-preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-left: 20px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 40px;
  justify-content: center;
}
.square {
  padding-bottom: 100%;
}
.smartphone-banner {
  padding-bottom: 16.667%;
}
/*
.ad191-1 {
  padding-bottom: 52.356%;
}
*/
.landscape {
  padding-bottom: 56.25%;
}
.portrait {
  padding-bottom: 177.78%;
}
.ad4-5 {
  padding-bottom: 125%;
}
.billboard {
  padding-bottom: 25.7%;
}
.leaderboard {
  padding-bottom: 12.36%;
}
.medium-rectangle {
  padding-bottom: 83.33%;
}
.halfpage {
  padding-bottom: 200%;
}
.wide-skyscraper {
  padding-bottom: 266%;
}
.skyscraper {
  padding-bottom: 375%;
}
.upload-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.order-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-details {
  margin-top: 20px;
}
.create-targeting {
  margin-top: 20px;
}
h2.createtitletext {
  text-align: left;
  color: #22255C;
}
.vspace {
  width: 100%;
  height: 20px;
}
.nowrap {
  flex-wrap: nowrap;
}
.create-item {
  margin-top: 20px;
}
.spend-item {
  margin-top: 20px;
  width: 150px;
}
.create-order-unit {
  color: #22255c;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.0em;
  margin-right: 10px;
  flex-wrap: nowrap;
}
.pointer {
  cursor: pointer;
}
.create-save-order-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: #d588f7;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 0 0 2px #d588f7,0 0 15px 2px #d588f7;
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.create-order-title {
  font-weight: 600;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--msft-card-font-color);
  font-size: var(--msft-article-heading-font-size, 1.2em);
  line-height: var(--msft-article-heading-line-height, 24px);
  -webkit-line-clamp: var(--heading-max-lines, 3);
  text-align: center;
  margin: 10px;
  outline: 0px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.step-1-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}
.step-2-3-container {
  display: flex;
  flex-direction: row;
  justify-content: inherit;
}
.step-2-3-container-left {
  width: 50%;
}
.contract-items {
  display: flex;
  margin-top: 18px;
}
.flex-row-reverse {
  display: flex;
}
.category {
  display: flex;
}
.create-dropdown-item {
  margin-right: 10px;
}
input#create-order-file-upload-thumbnail {
  display: none;
}
input#create-order-file-upload {
  display: none;
}
label.create-order-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
label.create-order-file-upload-thumbnail {
  display: block;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.preview-container {
  width: 25%;
  margin-left: 20px;
}
.media-preview {
  width: 100%;
  height: 64px;
  max-width: 300px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.media-preview-thumbnail {
  width: 64px;
  height: 64px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-preview {
  width: 100%;
}
.media-thumbnail {
  width: 64px;
  height: 64px;
  margin-right: 5px;
  overflow: none;
  cursor: pointer;
  border-radius: 15px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  transition: all 500ms ease-in-out;
}
.img media-thumbnail {
  width: 100%;
}
.product-preview-mobile {
  width: 100%;
}
.product-preview-desktop {
  width: 60%;
}
.product-banner-desktop {
  width: 100%;
  border-radius: 25px;
  background-size: cover;
  aspect-ratio: 2/1;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.product-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.order-carousel {
  width: 100%;
}
.order-thumbnails {
  display: flex;
}
.edit-product-card-preview {
  border: 2px dashed;
  border-radius: 20px;
  padding: 10px;
}
.options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.edit-offer {
  width: 30%;
}
.add-features {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-left: 10px;
  margin-bottom: 20px;
}
.rem-features {
  margin-left: 10px;
  color: #da2671;
}
.rem-features:hover {
  text-decoration: underline;
  cursor: pointer;
}
.offer-container-outer {
  padding: 1px;
  border-radius: 20px;
  background: radial-gradient(ellipse at top left, #3a9ae7 0, #b53cd3 62%, #041534 100%);
}
.offer-container-inner {
  color: #2d2d2d;
  margin: 3px;
  padding: 10px;
  border-radius: 15px;
  background: white;
}
.feature-tick {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.order-detail {
  margin-bottom: 10px;
}
.order-description {
  margin-bottom: 10px;
}
.delete-button-small:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #afafaf;
}
.delete-button-small {
  margin: 3px;
  font-size: 0.8em;
  pointer: cursor;
}
.margin-top-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.ql-editor {
  font-size: 18px;
}
.offer-description {
  min-height: 150px;
}
.local-switch-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.local-container {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
}
.local-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.location-input {
  font-color: #505050;
}
.c-input-title {
  margin-top: 15px;
  margin-bottom: 7px;
  font-size: .75em;
  font-weight: 700;
  color: #a046ff;
  text-align: left;
}
.orders-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
</style>

<style scoped>
.tab-selector {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #f5f5f5;
  border-bottom: 2px solid #ddd;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: #666;
  transition: color 0.3s ease;
}

.tab.active {
  color: #333;
}

.indicator {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 25%; /* Adjust based on number of tabs */
  background-color: #4a90e2;
  transition: transform 0.3s ease;
}

.tab-content {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  box-sizing: border-box;
}

/* Transition content */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.tab-content-wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@keyframes gnarlySlideEnter {
  0% {
    transform: scale(0.8) rotate(0deg) translateX(100%);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) rotate(-0deg) translateX(-0%);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(0) translateX(0);
    opacity: 1;
  }
}

@keyframes gnarlySlideLeave {
  0% {
    transform: scale(1) rotate(0) translateX(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) rotate(2deg) translateX(10%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.8) rotate(-2deg) translateX(-100%);
    opacity: 0;
  }
}

.gnarly-slide-enter-active {
  animation: gnarlySlideEnter 0.6s cubic-bezier(0.7, 0, 0.2, 1);
}
.gnarly-slide-leave-active {
  animation: gnarlySlideLeave 0.6s cubic-bezier(-0.7, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(-100%);
}

.one-hundred-percent {
  width: 100%;
}

.no-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.back-to-orders {
  display: flex;
  font-size: 1.2em;
  font-weight: bold;
}
.flex-col-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

</style>
