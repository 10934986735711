<template>
  <div class="flex-grow">

    <div class="messenger-container">

<!--
      <div class="conversation-title">
        <h3>{{conversationTitle}}</h3>
      </div>
-->

      <div
        class="flex-row fill-height"
        >

        <div
          class="conversations"
          :class="{ 'conversations-expanded': selectedConversation, 'conversations-hidden': !selectedConversation }"
          v-if="route.path.startsWith('/messenger/')"
        >
          <div
            class="conversation-bubble"
            v-for="(k,i) in conversations"
            key="k.room_id"
            @click="selectedConversation = k.room_id"
          >
            <div
              v-for="(j,r) in k.user_ids"
              key="j"
            >
              <div v-if="users[j]" class="user-profile-bubble">
                <img class="profile-image" :src="'../../' + users[j].image" />
                <div>
                  <div class="profile-text">
                    <b>{{ users[j].name }}</b>
                  </div>
                  <div
                    class="profile-text"
                  >
                     {{ k.message?.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="messages-container">
          <div id="message-space">
            <div
              v-for="(k,i) in messages"
            >
              <div
                class="messages-container-bubble-me"
                v-if="k.user_id === userInfo.user_id"
              >
                <div 
                  class="message-bubble-me"
                  v-html="textToLinks(k.message.text)"
                />
              </div>

              <div
                class="messages-container-bubble-other"
                v-else
              >
                <div 
                  class="message-bubble-other"
                  v-html="textToLinks(k.message.text)"
                />
              </div>
            </div>
          </div>

          <div class="message-input">
            <div class="message-input-left">
              <div>
              </div>
            </div>
            <div
              class="message-input-center"
            >
              <input
                class="message-input-field"
                type="text"
                placeholder="Hi .."
                v-model="inputValue"
                v-on:input="handleInput"
                @keydown.enter="handleEnter"
              />
              <div class="input-buttons-containers">
                <div
                  @click="sendMessage"
                  class="message-input-button"
                  v-html="getRawIcon('PLAY_ICON')"
                >
                </div>
                <div class="upload-icon-container">
                  <label
                    for="messengger-file-upload"
                  >
                    <div
                      class="message-input-button"
                      v-html="getRawIcon('IMAGE_ICON')"
                    >
                    </div>
                  </label>
                  <form class="abstract-upload">
                    <input
                      id="messengger-file-upload"
                      type="file"
                      accept="image/*,video/*"
                      @change="addCreativeFiles"
                    >
                  </form>
                </div>
                <div
                  @click="uploadAttachment"
                  class="message-input-button"
                  v-html="getRawIcon('ATTACHMENT_ICON')"
                >
                </div>
                <div
                  @click="emojiPicker = true"
                  class="message-input-button"
                  v-html="getRawIcon('EMOJI_ICON')"
                >
                </div>
                <div
                  class="relative"
                >
                  <Transition>
                    <EmojiPicker
                      v-if="emojiPicker"
                      v-click-away="onClickAway"
                      class="emoji-picker"
                      :native="true"
                      @select="onSelectEmoji"
                    />
                  </Transition>
                </div>
              </div>
            </div>
            <div class="message-input-right">
            </div>

          </div>

        </div>

      </div>
    </div>

    <Transition>
      <CModal
        v-if="showAddModal"
        color="#f9b115"
        title="Validation Error"
        @close="showAddModal = false"
      >
        <template #header>
          <div> {{ localize('TEXT_UPDATE') }} </div>
        </template>
        <template #body>
          <div>
            {{ showAddMessage }}

            <div
              @click="viewMessenger"
            >
        
              {{localize('VIEW_PROFILE')}}
              
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transition>

  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {translationStore} from "@/stores/translation";
import {inject, onMounted, onUnmounted, reactive, ref, toRaw, toRefs, unref, watch, watchEffect} from "vue";
import Axios from "axios";
import FormData from "form-data";
import {ERC20FactoryABI} from "../abi.js";
import { io } from "socket.io-client";
import EmojiPicker from 'vue3-emoji-picker'


export default {
    "name": "Messenger",
    "components": {
      EmojiPicker 
    },
    "props": {
    },
    setup (props) {

        const translation = translationStore(),
            serverConfig = toRefs(inject("serverConfig")),
            route = inject("route"),
            router = inject("router"),
            web3 = inject("web3"),
            userInfo = inject("userInfo"),
            socket = io("wss://www.thumpr.xyz"),
            selectedConversation = ref(),
            icon = iconStore(),
            showAddMessage = ref(),
            showAddModal = ref(false),
            inputValue = ref(),
            emojiPicker = ref(false),
            messages = ref([]),
            conversationTitle = ref(),
            conversations = ref([
            ]),
            users = ref({
            }),
            videoTypes = reactive([
                "video/mp4",
                "video/mpeg",
                "video/x-msvideo",
                "video/webm"
            ]),
            imageTypes = reactive([
                "image/gif",
                "image/jpeg",
                "image/avif",
                "image/png",
                "image/svg",
                "image/svg+xml",
                "image/webp"
            ]),
            maxVideoSize = ref("1000000000"),
            maxImageSize = ref("10000000"),
            textToLinks = function(text) {

              const words = text.split(' ');
              const convertedWords = words.map(word => {

                  if (word.includes('.')) {

                      return `<a href="${word.startsWith('http') ? word : 'http://' + word}" target="_blank" rel="noopener noreferrer">${word}</a>`;

                  }

                  return word;

              });

              return convertedWords.join(' ');

            },
            handleInput = function (e) {

            },
            handleEnter = function (e) {

                console.log('handleInput (enter)')
                if (e.keyCode === 13) {

                    sendMessage();

                }

            },
            onSelectEmoji = (emoji) => {

                if (inputValue.value) {

                    inputValue.value += emoji.i

                } else {

                    inputValue.value = emoji.i

                }

            },
            onClickAway = (event) => {

                console.log('clickAway')
                console.log(event)
                emojiPicker.value = false;

            },
            addProductMedia = async function (file, t, callback) {

                const data = new FormData();

                data.append(
                    "file",
                    file
                );

                data.append(
                    "type",
                    t
                );

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addProductFile`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "multipart/form-data"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            callback(response.data);

                        } else {

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            uploadAttachment = async function (file, t, callback) {

                const data = new FormData();

                data.append(
                    "file",
                    file
                );
                data.append(
                    "type",
                    t
                );

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addProductFile`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "multipart/form-data"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            callback(response.data);

                        } else {

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            viewMessenger = function() {

                window.open('https://www.thumpr.xyz/messenger/' + messenger.id, '_blank').focus();

            },
            getConversations = function (user_id) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getConversations`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            conversations.value.splice(0);

                            var u = [];

                            // remove orders on messenger tab
                            if (route.path.startsWith('/messenger/')) {

                                conversations.value = response.data.filter(item => !(item.metadata && item.metadata.orderId));

                            } else if (route.path.startsWith('/orders/')) {

                                conversations.value = response.data.filter(item => (item.metadata && item.metadata.orderId));
                                selectedConversation.value = route.params.room;

                            } else {

                                conversations.value = response.data;

                            }

                            if (!selectedConversation.value && conversations.value.length > 0) {
                                  selectedConversation.value = conversations.value[0].room_id;
                            }

                            for (var i=0; i<conversations.value.length; i++) {

                                for (var j=0; j<conversations.value[i].user_ids.length; j++) {

                                    if (!u.includes[conversations.value[i].user_ids[j]]) {

                                        u.push(conversations.value[i].user_ids[j]);

                                    }

                                }

                                conversations.value[i].user_ids
                                    = conversations.value[i].user_ids.filter(
                                      user_id => parseInt(user_id) !== userInfo.user_id);

                                // attach ws to each room
                                // socket.emit("joinRoom", conversations.value[i].room_id);

                            }

                            if (u.length > 0) {

                                getUserProfiles(u);

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getConversationMessages = function (roomId) {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getRoomMessages`,
                    {

                        "params": {

                            roomId

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            messages.value = response.data;

                            setTimeout(()=>{

                                const messageSpace = document.getElementById('message-space');
                                messageSpace.scrollTop = messageSpace.scrollHeight;

                            },100);

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getUserProfiles = function (u){
       
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getUserProfiles`,
                    {

                        "params": {

                            'users': u

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            for (var i=0; i<response.data.length; i++) {

                                users.value[response.data[i].user_id] = response.data[i];

                            }

                            // set current conversation if there is not explicitly in the url
                            if (!route.params.room) {

                                selectedConversation.value = conversations.value[0].room_id;
                                setConversationTitle();

                            }

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            setConversationTitle = function() {

                // not optimal but I don't have time bar bruteforcing everything
                for (var i in conversations.value) {

                    if (conversations.value[i].room_id == selectedConversation.value) {

                        if (conversations.value[0].metadata.name == "Direct Message") {

                            for (var j in conversations.value[0].user_ids) {

                                if (conversations.value[0].user_ids[j] != userInfo.user_id) {

                                }

                            }

                        }

                    }

                }
                conversationTitle.value = conversations.value[0]?.metadata?.name;

            },
            addCreativeFiles = function (e) {

                const files = e.target.files;
                // it's a for loop but iirc it only accepts one upload at a time?
                for (let i = 0; i < files.length; i++) {

                    const file = files[i];

                    if (imageTypes.indexOf(files[i].type) > -1) {

                        getMediaDimensions(
                            file,
                            "image",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addMedia(
                                    file,
                                    "image",
                                    (r) => {

                                        media.id = r.id;
                                        media.video = false;
                                        media.loading = false;
                                        media.media_filename = r.media_filename;
                                        media.media_filename_preview = r.media_filename_preview;
                                        media.original_filename = r.original_filename;
                                        media.url = r.url;

                                        messenger.value.media = media;

                                    }
                                );

                            }
                        );

                    } else if (videoTypes.indexOf(files[i].type) > -1) {

                        const file = files[i];

                        getMediaDimensions(
                            file,
                            "video",
                            (d) => {

                                const media = {};
                                media.dimensions = d;
                                media.loading = true;

                                addMedia(
                                    file,
                                    "video",
                                    (r) => {

                                        // delay as the server side image may not be ready
                                        setTimeout(
                                            () => {

                                                media.id = r.id;
                                                media.video = true;
                                                media.loading = false;
                                                media.media_filename = r.media_filename;
                                                media.media_filename_preview = r.media_filename_preview;
                                                media.original_filename = r.original_filename;
                                                media.url = r.url;

                                                messenger.value.media = media;

                                            },
                                            3000
                                        );

                                    }

                                );

                            }

                        );

                    } else {

                        console.log("not a supported type");
                        return;

                    }

                }

            },
            addMedia = async function (file, t, callback) {

                const data = new FormData();

                data.append(
                    "file",
                    file
                );
                data.append(
                    "type",
                    t
                );

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}addProductFile`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "multipart/form-data"

                        }

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                            callback(response.data);

                        } else {

                            callback();

                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            sendMessage = function () {

                if (!inputValue.value) {

                    return;

                }

                if (!selectedConversation.value) {

                    selectedConversation.value = route.params.room;

                }

                const data = {

                    roomId: selectedConversation.value,
                    message: {

                        text: inputValue.value

                    }

                }

                Axios.post(
                    `${process.env.VUE_APP_SERVER_URI}sendMessage`,
                    data,
                    {

                        "headers": {

                            "Content-Type": "application/json"

                        }

                    }

                ).
                    then((response) => {

                        if (response.status === 200) {

                            inputValue.value = "";

                            setTimeout(()=> {

                                const messageSpace = document.getElementById('message-space');
                                messageSpace.scrollTop = messageSpace.scrollHeight;

                            },100);

                        }

                });

            },
            getURL = function (endpoint) {

                return `${process.env.VUE_APP_SERVER_URI + endpoint}`;

            },
            getMediaDimensions = async function (f, t, c) {

                const dimensions = {};
                if (t === "video") {

                    const $video = document.createElement("video");
                    $video.src = URL.createObjectURL(f);
                    $video.addEventListener(
                        "loadedmetadata",
                        function (e) {

                            console.log(e);
                            dimensions.x = this.videoWidth;
                            dimensions.y = this.videoLength;
                            c(dimensions);

                        }
                    );
                    setTimeout(
                        () => {

                            $video.removeEventListener(
                                "loadedmetadata",
                                () => {},
                                false
                            );

                        },
                        3000
                    );

                } else if (t === "image") {

                    const img = new Image();
                    img.src = URL.createObjectURL(f);
                    img.onload = function () {

                        dimensions.x = img.width;
                        dimensions.y = img.height;
                        c(dimensions);

                    };

                } else {

                    c();

                }

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            updateView = function (i) {

                if (serverConfig.view === "desktop") {


                } else if (serverConfig.view === "tablet") {

                } else {

                }

            };

        onMounted(() => {

            socket.on("connect", () => {

                console.log("Connected to server");
                console.log('messages on mounted')
                socket.emit("connection", "hullo");

            });

            socket.on("newMessage", ({userId,roomId,message}) => {

                var newChat = true;

                for (var i in conversations.value) {

                    if (conversations.value[i].room_id == roomId) {

                        conversations.value[i].message = message[0].message;

                        newChat = false;

                    }

                }

                if (newChat === true) {

                    getConversations();

                } else {

                    messages.value.push(message[0]);

                    setTimeout(()=> {

                        const messageSpace = document.getElementById('message-space');
                        messageSpace.scrollTop = messageSpace.scrollHeight;

                    },100);

                }

            });

            setTimeout(()=> {

                getConversations();

            },300);

            socket.on("newChat", ({userId,roomId}) => {

                getConversations();

            });

            watch(
                route.params.room,
                () => {

                    selectedConversation.value = route.params.room;

                }
            );

            watch(
                selectedConversation,
                () => {

                    getConversationMessages(selectedConversation.value);
                    setConversationTitle();

                }
            );


            watch(
                serverConfig.chainId,
                () => {

                }
            );

            watch(
                inputValue,
                () => {

                }
            );

        });

        onUnmounted(() => {

            socket.disconnect();

        });

        return {"localize": translation.localize,
            icon,
            web3,
            route,
            textToLinks,
            getURL,
            getConversations,
            getConversationMessages,
            getUserProfiles,
            setConversationTitle,
            conversationTitle,
            gotoPath,
            userInfo,
            updateView,
            users,
            emojiPicker,
            onClickAway,
            onSelectEmoji,
            conversations,
            selectedConversation,
            messages,
            viewMessenger,
            addCreativeFiles,
            uploadAttachment,
            getRawIcon,
            inputValue,
            handleInput,
            handleEnter,
            addMedia,
            sendMessage,
            showAddMessage,
            showAddModal,
            getMediaDimensions,
            serverConfig,
            "icon": icon.get};

    }
};

</script>
<style scoped>
.margin-top {
  margin-top: 20px;
}
.messenger-circle {
  height: 256px;
  width: 256px;
  border: 1px solid #efefef;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
}
.file-items {
  padding: 10px;
  background-color: #eeebff;
  border-radius: 10px;
  font-size: 0.8em;
  margin-top: 10px;
  background: linear-gradient(1deg,#e5e5e5,transparent);
}
.file-name {
  color: #22255c;
  font-weight: bold;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  white-space: nowrap;
}
.file-item {
  font-family: sans-serif;
  color: #a0a0a0;
  margin-right: 10px;
}
input#create-messenger-file-upload {
  display: none;
}
label.create-messenger-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
input#create-intro-file-upload {
  display: none;
}
label.create-intro-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.segment-container {
  width: 100%;
  margin-left: 50px;
}
.create-save-button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  width: fit-content;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7c88,#ff46a4)
}
.conversations-expanded {
  width: 300px;
}

.conversations-hidden {
  display: none;
}

.messenger-video {
  width: 100%;
  max-width: 720px;
  margin: 20px;
  border-radius: 20px;
}
.messenger-container {
  display: flex;
  flex-direction: column;
  height: 50vh;
  max-width: 1200px;
  width: 100%;
  padding: 10px;
  border-radius: 12px;
  font-family: 'Arial', sans-serif;
  transition: transform 0.2s ease-in-out;
  margin-left: auto;
  margin-right: auto;
}

.messenger-container:hover {
}

/* Messenger Name */
.messenger-name {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

/* Messenger Title */
.messenger-title {
  font-size: 1.2em;
  font-weight: 600;
  color: #555;
  margin-bottom: 12px;
  text-align: center;
}

/* Messenger Description */
.messenger-description {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
  margin-bottom: 16px;
  text-align: justify;
}

/* Messenger Country */
.messenger-country {
  font-size: 0.9em;
  color: #888;
  font-weight: 600;
  text-align: right;
}

/* Additions to improve responsiveness */
@media (max-width: 768px) {
  .messenger-container {
    padding: 15px;
  }

  .messenger-name {
    font-size: 1.6em;
  }

  .messenger-title {
    font-size: 1.1em;
  }

  .messenger-description {
    font-size: 0.95em;
  }

  .messenger-country {
    font-size: 0.85em;
  }
}
.messenger-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
}
.conversation-bubble {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  margin: 0px 10px 10px 0px;
  font-size: 1em;
  height: 80px;
  border-radius: 30px;
  cursor: pointer;
  transition: box-shadow .3s ease;
  box-shadow: 1px 1px 5px rgba(0,0,0,.2);
}
.user-profile-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
}
.conversation-bubble:hover {
  box-shadow: 4px 4px 20px rgba(0,0,0,.3);
}
.conversation-bubble:active {
  box-shadow: 1px 1px 5px rgba(0,0,0,.2);
}
.messages-icon {
  margin-right: 20px;
  width: 48px;
  height: 48px;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flex-grow {
}
.messages-container-bubble-me {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: end;
  padding: 3px;
}
.messages-container-bubble-other {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 5px;
}
.message-bubble-me {
  display: flex;
  flex-grow: 1;
  padding: 3px 10px;
  margin: 0px 15px;
  border-radius: 12px;
  background-color: #349de8;
  color: white;
  width: fit-content;
}
.message-bubble-other {
  display: flex;
  flex-grow: 1;
  padding: 3px 10px;
  margin: 0px 15px;
  border-radius: 12px;
  background-color: #fff;
  color: #333;
  width: fit-content;
}
.messages-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-radius: 30px;
  background: #efefef;
}
#message-space {
  display: flex;
  flex-direction: column;
  overflow: auto;
  scroll-behavior: smooth;
}
.profile-image {
  width: 64px;
  height: 64px;
  border: 1px solid #efefef;
  border-radius: 50%;
  background: #efefef;
}
.profile-text {
  margin: 5px 10px;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.message-input-field {
  position: relative;
  display: flex;
  flex-grow: 1;
  font-size: 1em;
  align-items: center;
  margin: 10px;
  padding: 10px 20px;
  line-height: 30px;
  border-radius: 30px;
  min-width: 250px;
  outline: none;
  border: none;
}
input:focus {
  outline: none;
}
.message-input-center {
  position: relative;
  display: flex;
  flex-direction: row;
}
.conversations {
  overflow: auto;
  padding: 15px;
}
.fill-height {
  height: 100%;
}
.input-buttons-containers {
  position: absolute;
  right: 0;
  margin-right: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.message-input-button {
  width: 24px;
  height: 24px;
  padding: 5px;
  transition: filter 0.2s ease-in-out;
}
.message-input-button:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 10px cyan);
}
.upload-icon-container {
  position: relative
}
.abstract-upload {
  width: 100%;
  height: 100%;
  position: absolute;
}
input#messengger-file-upload {
  display: none;
}
label.messengger-file-upload {
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}
.relative {
  position: relative;
}
.emoji-picker {
  position: absolute;
  margin-top: -360px;
  margin-left: -250px;
}
.conversation-title {
  margin-left: 300px;
}
.flex-grow {
  flex-grow: 1;
}
a {
  color: inherit; /* Keeps the original text color */
  text-decoration: underline; /* Keeps the underline */
  background: none; /* Removes any background */
  border: none; /* Removes any border if there is one */
}

a:hover {
  color: inherit; /* Ensure no color change on hover */
  text-decoration: underline; /* Keep the underline on hover */
}

</style>
