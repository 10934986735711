<template>
  <div
    class="modal-dialog"
    v-if="tokens"
  >
    <LocalizedContent ref="lc" />
    <div class="flex-col">
      <div class="product-container" :class="respText">
        <div class="flex-col">
          <div class="product-title">
            {{ product.title }}
          </div>
          <div class="flex-row">
            <img :src="profileImage" class="small-profile-image">
            <div class="flex-center">
              <b> {{ profileName }} </b>
            </div>
          </div>
        </div>
        <div class="product-option">
          <div> {{ option }} ${{ orderAmount }} </div>
        </div>
      </div>
      <div class="super-line">
        <div class="super-text">
          {{ localize("TEXT_SELECT_TOKEN") }}
        </div>
        <div class="super-text maxtext">
          {{ localize("TEXT_BALANCE") }} : {{selectedToken}} : {{ tokens[selectedToken].balance }}
        </div>
      </div>
      <div class="pay-in-container">
        <div class="pay-in-outer">
          <div class="pay-in-inner">
            <div
              v-if="tokens[selectedToken]"
              class="token-dropdown"
              @click="openTokensModal('sell')"
            >
              <div class="pay-token-container-absolute margin-sell">
                <div v-if="isUrl(tokens[selectedToken].icon) === true">
                  <img
                    class="token-img"
                    :src="tokens[selectedToken].icon"
                  >
                </div>
                <div v-else>
                  <div
                    class="token-icon-payment"
                    v-html="getRawIcon(tokens[selectedToken].icon)"
                  />
                </div>
                <div class="symbol-text">
                  {{ tokens[selectedToken].symbol }}
                </div>
<!--
                <div
                  class="token-icon"
                  v-html="getRawIcon('EXPAND_ARROW_DOWN_LIGHT')"
                />
-->
              </div>
            </div>
            <div class="pay-amount">
              <div>
                <input
                  placeholder="0.00"
                  :value="amount"
                  @input="event => amount = event.target.value"
                  type="number"
                  step="0.1"
                  class="pay-amount-input max-text" />
              </div>
              <div v-if="tokens[selectedToken].price">
                <div class="pay-in-amount-subscript" v-if="tokens[selectedToken].price">
                  $ {{ (amount * tokens[selectedToken].price).toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col">
      <div class="sub-line">
        <div v-if="hourly == true" class="sub-text maxtext">
          <div class="hours">
            {{hours}} {{localize('TEXT_HOURS')}}
          </div>
          <div
            class="change-hours"
            @click="hours++"
          >+
          </div>
          <div
            class="change-hours"
            @click="hours = hours > 0 ? hours - 1 : 0"
          >-
          </div>
        </div>
      </div>
      <div class="payment-midsection">
        <div class="chains">
          <div
            v-for="(t,i) in chains"
            :key="i"
            @click="changeChain(t.symbol)"
            v-html="getRawIcon('BASE_LOGO_LIGHT')"
            class="chain-icon"
          />
           Base
        </div>
        <div class="flex-col">
          <div v-if="amount > tokens[selectedToken].price * tokens[selectedToken].balance" class="pay-warning">
            <b class="bigo">O</b> {{localize('TEXT_INSUFFICIENT_BALANCE')}}
          </div>
          <div v-if="amount * tokens[selectedToken].price % orderAmount != 0" class="pay-warning">
            <b class="bigo">O</b> {{localize('TEXT_CUSTOM_PRICING')}} <i>({{localize('CLEAR_PRICING')}})</i>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div
      v-if="buyable"
      @click=placeOrder
      class="pay-text-box"
    >
      <div>
        <span><span class="pay-text">{{localize('TEXT_PLACE_ORDER')}}</span></span>
      </div>
      <Spinner
        v-if="isTransaction"
        class="spinner-absolute"
      />
    </div>
    <div
      v-else
      class="pay-text-box-disabled"
    >
      <div>
        <span><span class="pay-text-disabled">{{localize('TEXT_PLACE_ORDER')}}</span></span>
      </div>
      <Spinner
        v-if="isTransaction"
        class="spinner-absolute"
      />
    </div>
    <div>
      <span
        id="message"
        class="success"
      >
        {{ message }}
      </span>
    </div>

    <Transform>
      <CModal
        v-if="showTokensModal"
        width="small"
        color="#efefef"
        backgroundColor="#efefef"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showTokensModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_CHOOSE_TOKEN") }} </div>
        </template>
        <template #body>
          <div
            class="token-modal-container"
          >
            <div
              v-for="(t,i) in tokens"
              :key="i"
              @click="changeToken(t.symbol)"
            >
              <div class="pay-token-container margin-sell">
                <div class="flex-row">
                  <div v-if="isUrl(t.icon) === true">
                    <img
                      class="token-img"
                      :src="t.icon"
                    >
                  </div>
                  <div v-else>
                    <div
                      class="token-icon-payment"
                      v-html="getRawIcon(t.icon)"
                    />
                  </div>
                  <div class="flex-col">
                    <div class="symbol-text">
                      {{ t.symbol }}
                    </div>
                    <div class="subscript">
                      {{ t.name }}
                    </div>
                  </div>
                </div>
                <div class="token-pricing">
                  <div class="flex-col-right">
                    <div class="super-text flex-row">
                      <div class="num-short"> {{ t.balance }} </div>
                    </div>
                    <div class="subscript-right num-short"> ${{ t.price }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>

    <Transform>
      <CModal
        v-if="showSuccessModal"
        width="small"
        color="#efefef"
        backgroundColor="#EFEFEF"
        secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
        @close="showSuccessModal = false"
      >
        <template #header>
          <div> {{ localize("TEXT_SUCCESS") }} </div>
        </template>
        <template #body>
          <div>
            {{localize('PLACE_ORDER_SUCCESS')}}
            <div
              class="success"
              v-html="getRawIcon('CIRCLE_CHECK')"
            />
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>

    <Transform>
      <CModal
        v-if="showFailedModal"
        color="#fff"
        width="small"
        @close="showFailedModal = false"
      >
        <template #header>
          <div> Error </div>
        </template>
        <template #body>
          <div>
            {{localize('PLACE_ORDER_FAILED')}}
          </div>
        </template>
        <template #footer>
          <div class="hidden" />
        </template>
      </CModal>
    </Transform>

  </div>
</template>
<script>

import {iconStore} from "@/stores/icon";
import {emit, inject, onMounted, ref, toRef, toRefs, watch} from "vue";
import {translationStore} from "@/stores/translation";
import Axios from "axios";
import Spinner from "./Spinner.vue";
//import {ERC20ABI, orderBookABI} from "../abi.js";
import {ERC20ABI, orderBookABI} from "../abi.mjs";
import { getContract } from 'viem';
import Web3 from "web3";

export default {
    "name": "Payment",
    "components": {
        Spinner
    },
    "props": {
        "product": {
            type: Object,
            default: ""
        },
        "profileName": { 
            type: String,
            default: ""
        },
        "profileImage": {
            type: String,
            default: ""
        },
        "payToken": {
            type: String,
            default: ""
        },
        "payTokens": {
            type: Array,
            default: []
        },
        "payOption":{
            type: String,
            default: ""
        },
        "payAmount":{
            type: Number,
            default: 0
        },
        "hourly":{
            type: Boolean,
            default: false
        }
    },
    "emits": [
        "hide-modal-event",
        "get-orders"
    ],
    setup (props, {emit}) {

        const web3 = inject("web3"),
            eth = inject("eth"),
            eBus = inject("eBus"),
            userInfo = inject("userInfo"),
            serverConfig = toRefs(inject("serverConfig")),
            route = inject("route"),
            router = inject("router"),
            icon = iconStore(),
            hours = ref(1),
            translation = translationStore(),
            showTokensModal = ref(false),
            showFailedModal = ref(false),
            showSuccessModal = ref(false),
            selectedToken = ref('USDC'),
            respText = ref('resp-text-desktop'),
            wallet = ref(),
            chains = ref({
                "BASE":{
                    icon: "BASE_LOGO_LIGHT",
                    symbol: "BASE",
                    name: "Base",
                    chainId: "8453"
                }
            }),
            tokens = ref({
                "USDT":{
                    icon: "USDT_LOGO_LIGHT",
                    symbol: "USDT",
                    name: "Tether",
                    address: "0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2",
                    chain: "Base",
                    chainId: "8453",
                    balance: 0,
                    dollarBalance: 0,
                    price: 1
                },
                "USDC":{
                    icon: "USDC_LOGO_LIGHT",
                    symbol: "USDC",
                    name: "Circle",
                    address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
                    chain: "Base",
                    chainId: "8453",
                    balance: 0,
                    dollarBalance: 0,
                    price: 1
                }
/*
smart contract has bug, it can't accept eth at the moment, simple fix
                "ETH":{
                    icon: "ETHEREUM_LIGHT",
                    symbol: "ETH",
                    name: "Ethereum",
                    address: "0", // 0 for native
                    chain: "Base",
                    chainId: "8453",
                    balance: 0,
                    dollarBalance: 0,
                    price: 1
                }
*/
            }),
            buyable = ref(false),
            isTransaction = ref(false),
            amount = ref(0),
            hourly = toRef(props, 'hourly'),
            orderAmount = toRef(props, 'payAmount'),
            option = toRef(props, 'payOption'),
            product = toRef(props, 'product'),
            profileName = toRef(props, 'profileName'),
            profileImage = toRef(props, 'profileImage'),
            currency = ref('USD'),
            payFee = ref(),
            message = ref(),
            emitAppEvent = (e, d) => {

                eBus.emit(
                    "app-event",
                    {
                        "action": "setNetwork",
                        "data": ""
                    }
                );

            },
            gotoPath = function (t) {

                router.push({"path": `/${t}`}).catch((err) => {

                    throw err;

                });

            },
            openOrder = function(orderId) {

                const params = {

                    orderId: orderId,
                    users: [product.value.user_id, userInfo.user_id]

                }

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}openChat`,
                    {

                        params

                    }
                ).
                    then((response) => {

                        if (response.status === 200) {

                              if (response.data.roomId) {

                                  gotoPath('orders/' + orderId + '/' + response.data.roomId);

                              }

                        }

                })

            },
            getAccount = async function () {

                try {

                    const accounts = await web3.value.getAccounts();

                    if (accounts) {

                        return accounts[0];

                    } else {

                        gotoPath('login/');

                    }

                } catch (e) {
                }

            },
            getTokenBalance = async function (t) {

                if (tokens.value[t].address == 0) {

                   try {

                        const balanceWei = await web3.value.eth.getBalance(wallet.value);
                        const balanceEth = web3.value.utils.fromWei(balanceWei, 'ether');

                        tokens.value[t].balance = balanceEth;
                        
                    } catch (error) {

                        console.error(`Error fetching balance: ${error.message}`);
                        tokens.value[t].balance = 0;

                    }

                } else {

                    const tokenContract = new web3.value.eth.Contract(ERC20ABI, tokens.value[t].address);

                    try {

                        const balanceWei = await tokenContract.methods.balanceOf(wallet.value).call();

                        const balanceUsd = parseInt(balanceWei) / (10**6);
                        tokens.value[t].balance = balanceUsd;

                        console.log("Payment tokens.value["+t+"].balance " + tokens.value[t].balance)

                    } catch (error) {

                        console.log("error getting balance")
                        console.log(error)
                        tokens.value[t].balance = 0;

                    }

                }

                if (tokens.value[selectedToken.value].balance >= amount.value) {

                    buyable.value = true;

                } else {

                    buyable.value = false;

                }

                getPrice(t, tokens.value[t], function(){});

            },
            setChain = function () {

                let chainId = 8453;
                serverConfig.chainId = parseInt(chainId);

                eth.value.request({
                    "method": "wallet_switchEthereumChain",
                    "params": [{"chainId": web3.value.utils.toHex(serverConfig.chainId)}]
                });

            },
            placeOrder = async function () {

                const selectedProvider = localStorage.getItem("selectedProvider");

                const addr = await getAccount(),
                    chainId = await web3.value.eth.getChainId(),
                    obc = new web3.value.eth.Contract( // order book contract
                        orderBookABI,
                        serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value
                    );

                // make sure that this is a valid contract (prevent spend to non contract)
                const code = await web3.value.eth.getCode(serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value);
                if (code === "0x") {

                    console.log("Payment Invalid Orderbook Contract")
                    return;

                }

                var balance = 0, orderPrice = 0, value = 0;

                if (tokens.value[selectedToken.value].address == 0) { // native (ETH) purchase

                    const balanceWei = await web3.value.eth.getBalance(wallet.value);
                    balance = web3.value.utils.fromWei(balanceWei, 'ether');
                    orderPrice = (amount.value * 10**6).toString();

                } else { // ERC20 purchase

                    const ptc = new web3.value.eth.Contract( // payable token contract
                        ERC20ABI,
                        tokens.value[selectedToken.value].address
                    );

                    let balance = await ptc.methods.balanceOf(addr).call();
                    balance = web3.value.utils.toWei(balance, 'ether');
                    orderPrice = (amount.value * 10**6).toString();

                    try {

                        const receipt = await ptc.methods.approve(
                            serverConfig.VUE_APP_ORDERBOOK_ADDRESS.value,
                            orderPrice
                        ).send({"from": addr});

                    } catch (e) {

                        console.log(e)

                    }

                }

                isTransaction.value = true;

                // wait for the approve to finish (TODO: we should look ath the event)
                await new Promise(r => setTimeout(r, 3000));

                // first run to estimate gas
                let gasLimit = await obc.methods.newOrder(
                    product.value.account,
                    product.value.id,
                    orderPrice,
                    tokens.value[selectedToken.value].address
                ).estimateGas(
                    {
                        from: addr,
                        value
                    },
                    (error, estimatedGas) => {
                        console.log("error in estimategas")
                        console.log(error)
                    }
                );

                // get a more accurate gas limit
                gasLimit = gasLimit <= 50000
                    ? gasLimit * 3
                    : gasLimit * 1.2;
                let gasPrice = await web3.value.eth.getGasPrice();

                gasLimit = Math.floor(gasLimit);
                gasPrice = BigInt(gasPrice) * 12n / 10n;
                gasPrice = web3.value.utils.toHex(Number(gasPrice));

                await obc.methods.newOrder(
                    product.value.account,
                    product.value.id,
                    orderPrice,
                    tokens.value[selectedToken.value].address
                ).send({
                    "from": addr,
                    to: obc.options.address,
                    value,
                    gasPrice,
                    gasLimit
                }).
                    then((jsonRpcResult) => {

                        console.log("jsonRpcResult")
                        console.log(jsonRpcResult)

                        if (jsonRpcResult.events?.newOrderEvent?.returnValues) {

                            const orderId = Number(jsonRpcResult.events.newOrderEvent.returnValues[0]);
                            const transactionHash = jsonRpcResult.events?.newOrderEvent?.transactionHash;
                            const contract = jsonRpcResult.events?.newOrderEvent?.address;

                            let orderBN = jsonRpcResult.events.newOrderEvent.returnValues[1];

                            let order = JSON.stringify(orderBN, (key, value) => {

                                return typeof value === "bigint" ? Number(value) : value;

                            });

                            Axios.post(
                                `${process.env.VUE_APP_SERVER_URI}addOrder`,
                                {
                                    orderId,
                                    order,
                                    contract,
                                    transactionHash,
                                    chainId: Number(chainId)
                                }
                            ).
                            then((response) => {

                                if (response.status === 200) {

                                    if (parseInt(orderId)) {

                                        showSuccessModal.value = true;

                                        setTimeout(()=>{

                                            //gotoPath('orders/' + orderId);
                                            openOrder(orderId);

                                        },5000);

                                    } else {

                                        showFailedModal.value = true;

                                    }

                                } else {

                                    emitHideModal();

                                }

                          });

                        }

                  });

            },
            getPrice = function(ticker, token, callback) {

                console.log("Payment getPrice " + ticker)

                if (ticker == "USDC" || ticker == "USDT") {

                    token.price = 1.0;
                    callback();

                } else {

                    Axios.get(
                        "https://price-api.crypto.com/price/v1/exchange/ethereum",
                        {

                            "params": {

                            }

                        }
                    ).
                        then((response) => {

                            if (response.data) {

                                serverConfig.priceFeed.ethPriceUSD = response.data.fiat.usd;
                                token.price = response.data.fiat.usd;

                                callback();

                            }

                        });

                }

            },
            openTokensModal = function(bos) {

                showTokensModal.value = true

            },
            changeToken = function (t) {

                getTokenBalance(t);

                selectedToken.value = t;

                showTokensModal.value = false;

            },
            emitHideModal = () => {

                emit(
                    "hide-modal-event",
                    false
                );

            },
            getAllTokens = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getAllTokens`,
                    {
                        "params": {
                        }
                    }
                ).
                    then((response) => {

                        if (response.status === 200 && response.data) {


                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            getMyTokens = function () {

                Axios.get(

                    `${process.env.VUE_APP_SERVER_URI}getMyTokens`,
                    {
                        "params": {
                        }
                    }
                ).
                    then((response) => {

                        loading.value = false;

                        if (response.status === 200 && response.data) {


                        }

                    }).
                    catch((error) => {

                        throw error;

                    });

            },
            isUrl = function (i) {

                return i.includes("://");

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            close = function () {

                emitHideModal();

            },
            calculateAmount = function() {

                getPrice(selectedToken.value, tokens.value[selectedToken.value], function () {

                    if (selectedToken.value === "ETH") {

                        amount.value /= tokens.value[selectedToken.value].price*hours.value; 

                    } else {

                        amount.value = orderAmount.value*hours.value;

                    }

                });

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    respText.value = "resp-text-desktop";

                } else if (serverConfig.view === "tablet") {

                    respText.value = "resp-text-desktop";

                } else {

                    respText.value = "resp-text-mobile";

                }

            };

        onMounted(async () => {

            wallet.value = await getAccount();
            if (typeof wallet.value == "string") {

                wallet.value = web3.value.utils.toChecksumAddress(wallet.value);

            }

            setChain();

            watch(
                selectedToken,
                () => {

                    calculateAmount();

                }
            );

            watch(
                amount,
                () => {

                    //calculateAmount();
                    if (tokens.value[selectedToken.value].balance >= amount.value) {

                        buyable.value = true;

                    } else {

                        buyable.value = false;

                    }

                }
            );

            watch(
                hours,
                () => {

                    calculateAmount();

                }
            );

            watch(
                serverConfig.view,
                () => {

                    updateView();

                }
            );

            setTimeout(() => {

                emitAppEvent(
                    "setNetwork",
                    serverConfig.chainId.value
                );

                if (serverConfig.chainId.value != 8453) {
                }

                amount.value = orderAmount.value;

                for (const t of Object.keys(tokens.value)) {

                    getTokenBalance(t);

                }

                updateView();

            }, 100);

        });

        return {"localize": translation.localize,
            serverConfig,
            userInfo,
            emitAppEvent,
            isTransaction,
            getPrice,
            product,
            chains,
            hours,
            buyable,
            openOrder,
            profileName,
            profileImage,
            changeToken,
            calculateAmount,
            openTokensModal,
            showTokensModal,
            showSuccessModal,
            showFailedModal,
            getAccount,
            getTokenBalance,
            selectedToken,
            gotoPath,
            wallet,
            payFee,
            amount,
            option,
            hourly,
            orderAmount,
            placeOrder,
            setChain,
            currency,
            isUrl,
            respText,
            updateView,
            getRawIcon,
            tokens,
            web3,
            eth,
            icon,
            close,
            emitHideModal};

    }
};

</script>
<style scoped>
input .amount {
  style: none;
}
.super-line {
  display: flex;
  justify-content: space-between;
  margin: 5px 10px;
}
.super-text {
  font-size: .9em;
  color: #999;
  text-align: right;
}
.sub-line {
  display: flex;
  justify-content: flex-end;
  margin: 5px 10px;
}
.sub-text {
  display: flex;
  font-size: .9em;
  color: #333;
  text-align: right;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.title-mobile {
  margin-left: 0px;
}
.title-desktop {
  margin-left: 10px;
}
.subscript-right {
  color: #777;
  font-size: 0.7em;
  font-weight: bold;
  text-align: right;
}
.content-container {
  margin-top: 150px;
}
.modal-dialog {
  pointer-events: all;
}
.send-inactive {
  float: left;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #ff7dcb;
  color: #ff7dcb;
  font-size: 0.8em;
  font-weight: bold;
}
.send-active {
  float: left;
  cursor: pointer;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 5px;
  border-color: #e10087;
  background-color: #e10087;
  font-weight: bold;
  color: #fff;
  font-size: 0.8em;
}
.pay-in-outer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  border-radius: 15px;
}
.pay-in-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  margin: 5px;
  border-radius: 12px;
}
.pay-amount {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}
.pay-in-amount-subscript {
  color: #999;
  font-size: 0.75em;
  font-weight: bold;
  text-align: right;
  margin-right: 14px;
}
.pay-amount-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: inherit;
  font-size: 1.44em;
  color: white;
  max-width: 250px;
  height: 30px;
  text-align: right;
  margin: 0px 10px;
  color: #777;
}
.reverse-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 35px;
  position: relative;
}
.reverse-pay-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 20px;
  width: 32px;
  height: 32px;
  padding: 2px;
  color: #777;
  border: 1px solid #efefef;
  cursor: pointer;
  background-color: #333;
  transition: all 300ms ease-in-out;
}
.reverse-pay-icon:hover {
  background: radial-gradient(#4e2e4c, #393939);
  color: rgb(255, 124, 245);
}
.error {
  float: left;
  margin: 15px;
  color: grey;
}
.success {
  float: left;
  margin: 15px;
  color: #e10087;
}
.pay-text-box-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #efefef;
}
.pay-text-box-disabled:hover {
  cursor: not-allowed !important;
}
.pay-text-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #efefef;
}
.pay-text-box:hover {
  border: 2px solid #46b6ff;
  cursor: pointer;
}
.pay-text {
  width: 100px;
  font-size: 1.2em;
  font-weight: bold;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pay-text-disabled {
  width: 100px;
  font-size: 1.2em;
  font-weight: bold;
  background: #777;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#message {
  font-size: 0.8em;
  margin: 15px;
  transition: all 500ms ease-in-out;
}
.token-dropdown {
  display: flex;
  align-items: center;
  position: relative;
  color: #fff;
}
.symbol-text {
  color: #222222;
  font-size: 0.85em;
  font-weight: bold;
}
.margin-sell {
  margin: 10px;
  padding: 9px 12px;
}
.margin-buy {
  margin: 10px;
  padding: 10px;
}
.pay-token-container-absolute {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #efefef;
  border-radius: 9px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.pay-token-container-absolute:hover {
  border: 2px solid #46b6ff;
  box-shadow: inset 0px 0px 10px 1px #46b6ff, 0px 0px 5px 1px #46b6ff;
}
.pay-token-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #efefef;
  border-radius: 9px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.pay-token-container:hover {
  border: 2px solid #46b6ff;
  box-shadow: inset 0px 0px 10px 1px #46b6ff, 0px 0px 5px 1px #46b6ff;
}
.rsi {
  padding-top: 5px;
}
textarea:focus, input:focus{
    outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.circ{
    opacity: 0;
    stroke-dasharray: 130;
    stroke-dashoffset: 130;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.tick{
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
    -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
    -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
    -o-transition: stroke-dashoffset 1s 0.5s ease-out;
    transition: stroke-dashoffset 1s 0.5s ease-out;
}
.success + svg .path{
    opacity: 1;
    stroke-dashoffset: 0;
}
.carousel__prev, .carousel__next {
  background-color: white !important;
  padding: 10px !important;
  border-radius: 50% !important;
  opacity: 0.8 !important;
  transition: opacity 0.2s ease-in-out !important;
}

.carousel__prev:hover, .carousel__next:hover {
  opacity: 1 !important;
}

.carousel__prev--disabled, .carousel__next--disabled {
  background-color: #f0f0f0 !important;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
.pay-in-container {
  width: 100%;
}
.num-short {
  color: #777;
  max-width: 75px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.small-profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 20px;
}
.product-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #efefef;
  border-radius: 20px;
}
.product-title {
  font-weight: bold;
  margin: 10px 0px;
}
.flex-col-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.product-option {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: #fff;
  padding: 20px;
  background: black;
  border-radius: 25px;
  text-align: center;
}
.token-icon-payment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.resp-text-desktop {
  font-size: 1.2em;
  font-weight: bold;
}
.resp-text-mobile {
  font-size: 0.9em;
  font-weight: bold;
}
.payment-midsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.chains {
  display: flex;
  align-items: center;
  padding: 20px;
}
.bigo {
  font-size: 1.1em;
}
.pay-warning {
  font-size: 0.9em;
  color: #e4176c;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.max-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.spinner-absolute {
  margin-left: 20px;
}
.chain-icon {
  margin-right: 10px;
  width: 32px;
  height: 32px;
}
.change-hours {
  font-weight: bold;
  margin: 0px 10px;
}
.change-hours:hover {
  cursor:pointer;
}
</style>

